<template>
    <template v-if="pdfUrl!=''">
        <iframe class="full-screen-element" :src="`${baseUrl}/pdfjs/web/viewer.html?file=${pdfUrl}`" width="100%"
            height="600px" frameborder="0"></iframe>
    </template>
	<div style="width: 100%;" v-else>
		<empty v-if="finish"></empty>			 
	</div>
</template>
<script>
// @ is an alias to /src
import {
    getSystemLang,
    getLangAll
} from "../../lang";
import axiosx from '../../axios/postx';
import axios from '../../axios/request';
import empty from "../../components/empty.vue";
export default {
    data() {
        return {
            langs: '',
            systemlang: 'cn',
            fileurl: '',
            baseUrl: window.location.origin,  // 本地开发时为开发服务器地址  
            pdfUrl: "",
            finish: false
        }
    },
    props: {

    },
    name: 'Home',
    components: {
		empty
    },
    created() {
        this.systemlang = getSystemLang();
        this.langs = getLangAll(this.systemlang);
        var type = this.$route.query.type;// huiyuanshouce yingka
        axios('sgrank/getopenwindow.json', { type: type })
            .then((res) => {
                if (res.data.data.fileurl == undefined) {
                    this.pdfUrl = ""
                } else {
                    var url = res.data.data.fileurl
                    url = url.replace("//www.clpga.org","//"+window.location.host);
                    url = url.replace("//clpga.org","//"+window.location.host);
                    url = url.replace("//www.clpga.com","//"+window.location.host);
                    url = url.replace("//clpga.com","//"+window.location.host);
                    url = url.replace("http:",window.location.protocol);
                    url = url.replace("https:",window.location.protocol);
                    this.pdfUrl = encodeURIComponent(url);
                }
                this.finish = true;
            })
    },
    setup() {
    },
    methods: {

    }
}
</script>
<style scoped>
#myiframe {
    border: none;
    /* 可选：移除边框以获得更干净的全屏效果 */
    /* pointer-events: none; */
}

.full-screen-element {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
</style>