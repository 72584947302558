<template>
	<navs></navs>
	<Btn></Btn>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_footer1_tit_box">
				<div class="lf_foot_tit_text1">个人中心</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2">Personal Center</div>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_banner_box">
				<img class="lf_match_banner_box_img" src="../../assets/images/u28535.svg" alt="">
				<div class="lf_opacity_div">
					<img v-if="userInfo.pl_photo!=''" :src="userInfo.pl_photo" alt="">
					<div class="lf_opacity_div_1">{{systemlang == 'cn'?(userInfo.pl_cn_name?userInfo.pl_cn_name:''):(userInfo.pl_en_name?userInfo.pl_en_name:'')}} | <text>{{systemlang != 'cn'?(userInfo.pl_cn_name?userInfo.pl_cn_name:''):(userInfo.pl_en_name?userInfo.pl_en_name:'')}}</text></div>
				</div>
				<a class="lf_btn_l2" @click="showchangepwd" href="javascript:void(0)">{{langs.text_ChangePassword}}</a>
				<a class="lf_btn_ll" @click="gouOutD" href="javascript:void(0)">{{langs.Quit}}</a>
			</div>
			<div class="lf_qysc_box">
				<img class="lf_qysc_box_1" src="../../assets/images/u752.png" alt="" />
				<router-link target="_blank" :to="{path:'/openwindow',query:{type:'huiyuanshouce',lang:systemlang}}">
					<div class="lf_qysc_box_2">{{langs.PlayerHandbook_tt}}</div>
				</router-link>
				<router-link target="_blank" :to="{path:'/openwindow',query:{type:'yingka',lang:systemlang}}">
					<div class="lf_qysc_box_3">{{langs.HardCardInfo_tt}}</div>
				</router-link>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_tab_tit_box lf_tab_tit_box_w">
				<div class="lf_tab_box">
					
					<div  @click="goDan('csbmmd')" :class="tFlag=='csbmmd'?'lf_tab_box_select':''">{{langs.EntryOrder}}</div>
					<div  @click="goDan('qtdd')" :class="tFlag=='qtdd'?'lf_tab_box_select':''">{{langs.OtherOrder}}</div>
					<div  @click="goDan('ccbm')" :class="tFlag=='ccbm'?'lf_tab_box_select':''">{{langs.SignUp}}</div>
					<!-- <div  @click="goDan('jsjyd')" :class="tFlag=='jsjyd'?'lf_tab_box_select':''">{{langs.PickupDropoffService}}</div> -->
					<div  @click="goDan('tbtx')" :class="tFlag=='tbtx'?'lf_tab_box_select':''">{{langs.Attention}}</div>
					<div  @click="goDan('sg')" :class="tFlag=='sg'?'lf_tab_box_select':''">{{langs.text_StrokeGained}}</div>
					
				</div>
			</div>
			<div class="all_body_swiper lf_tab_tit_box_m">
				<!--csbmmd:参赛报名订单 qtdd:其他订单  ccbm:参赛报名 jsjyd:接送机预定 tbtx:特别提醒-->
				<swiper ref="aaa" :modules="modules" :autoplay="false" :initialSlide="nextpage" :pagination="{ clickable: true }" @swiper="onSwiper1"
					@slideChange="onSlideChange" class="lf_banner_div_left_img">
					<swiper-slide>
			
						<div class="lf_tab_tit_box">
							<div class="lf_tab_box">
								<div  @click="goDan('csbmmd')" :class="tFlag=='csbmmd'?'lf_tab_box_select':''">{{langs.EntryOrder}}</div>
								<div  @click="goDan('qtdd')" :class="tFlag=='qtdd'?'lf_tab_box_select':''">{{langs.OtherOrder}}</div>
								<div  @click="goDan('ccbm')" :class="tFlag=='ccbm'?'lf_tab_box_select':''">{{langs.SignUp}}</div>
								<!-- <div  @click="goDan('jsjyd')" :class="tFlag=='jsjyd'?'lf_tab_box_select':''">{{langs.PickupDropoffService}}</div> -->
								<div  @click="goDan('tbtx')" :class="tFlag=='tbtx'?'lf_tab_box_select':''">{{langs.Attention}}</div>
								
								<div class="rightBtn" @click="slideNext"></div>
							</div>
						</div>
			
					</swiper-slide>
			
			
					<swiper-slide>
						<div class="lf_tab_tit_box">
							<div class="lf_tab_box">
								<div class="leftBtn" @click="slidePrev"></div>
								<div  @click="goDan('qtdd')" :class="tFlag=='qtdd'?'lf_tab_box_select':''">{{langs.OtherOrder}}</div>
								<div  @click="goDan('ccbm')" :class="tFlag=='ccbm'?'lf_tab_box_select':''">{{langs.SignUp}}</div>
								<!-- <div  @click="goDan('jsjyd')" :class="tFlag=='jsjyd'?'lf_tab_box_select':''">{{langs.PickupDropoffService}}</div> -->
								
								<div  @click="goDan('tbtx')" :class="tFlag=='tbtx'?'lf_tab_box_select':''">{{langs.Attention}}</div>
								<div  @click="goDan('sg')" :class="tFlag=='sg'?'lf_tab_box_select':''">{{langs.text_StrokeGained}}</div>
								
							</div>
						</div>
					</swiper-slide>
				</swiper>
				
			</div>
		</el-col>
	</el-row>
	<Lists v-if="tFlag=='csbmmd'"></Lists>
	<Listsother v-if="tFlag=='qtdd'"></Listsother>
	<Transfer v-if="tFlag=='jsjyd'"></Transfer>
	<Entry v-if="tFlag=='ccbm'" :parentgoDan="goDan" ></Entry>
	<TBTX v-if="tFlag=='tbtx'"></TBTX>
	<ListsSG v-if="tFlag=='sg'"></ListsSG>
	<Footer1></Footer1>
	<!-- 修改密码 -->
	<div class="lf_login_page" v-if="changepwdshow">
		<div class="lf_login_inner_box">
			<img class="lf_close_img" @click="loginOut" src="../../assets/images/close.png" alt="">
			<div class="lf_login_inner_tit"><img src="../../assets/images/u28953.png" alt="">{{langs['text_ChangePassword']}}</div>
			<div class="lf_login_inner_input_box">
				{{langs.text_Newpassword}}
				<input v-model="pwd_new" :placeholder="langs.text_Newpassword" type="password">
			</div>
			<div class="lf_login_inner_input_box">
				{{langs.text_Newpasswordagain}}
				<input v-model="pwd_newagin" :placeholder="langs.text_Newpasswordagain" type="password">
			</div>
			<div class="lf_login_btn_B" @click="loginD">{{langs['Confirm']}}</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import Ad from "../../components/Ad.vue";
	import Lists from "../../components/Login/Lists.vue";
	import Listsother from "../../components/Login/Listsother.vue";
	import Transfer from "../../components/Login/Transfer.vue";
	import Entry from "../../components/Login/Entry.vue";
	import TBTX from "../../components/Login/TBTX.vue";
	import ListsSG from "../../components/Login/ListsSG.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	import axiosx from '../../axios/postx';
	import axios from '../../axios/request';
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	
	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				opacityF: true,
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				matchInfo:{},
				tFlag:'csbmmd', //csbmmd:参赛报名订单 qtdd:其他订单  ccbm:参赛报名 jsjyd:接送机预定 tbtx:特别提醒
				bFlag:'ssxx',  //ssxx:赛事信息 csqy:参赛球员 qcjs:球场介绍 fzb:分组表 jstj:技术统计
				userInfo:{},
				pwd_new:"",
				pwd_newagin:"",
				changepwdshow:false
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			Ad,
			Lists,
			Transfer,
			Entry,
			Listsother,
			ListsSG,
			TBTX

		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			
			//获取banner
			axiosx('user/userinfo.json?rand='+Math.random())
            .then((res)=>{
                let data = res.data;
				console.log(data);
				if(data.msgCode != '200')
				{
					//跳转到首页面
					let routeUrl = this.$router.resolve({
							path: "/",
							query:{lang:this.systemlang}
						});
						this.userName = '';
						window.open(routeUrl.href, '_self');
				}
            });

			//获取banner
			axiosx('user/usershow.json?rand='+Math.random())
            .then((res)=>{
                let data = res.data;
				console.log(data);
				if(data.msgCode == '200')
				{
					this.userInfo = data.data;
				}
            });

			let id = this.$route.query.type;
			// console.log('type',id);
			if(id)
			{
				if(id == 'ccbm')
				{
					this.tFlag = id;
				}
			}
		},
		setup() {
			let useSwiper = undefined;
			const onSwiper = (swiper) => {
				console.log('111',swiper);
			};
			const onSwiper1 = (swiper) => {
				console.log('111',swiper);
				useSwiper = swiper;
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const slidePrev = () => {
			      console.log(useSwiper)
			      useSwiper.slidePrev()
			    }
			const slideNext = () => {
			  console.log(useSwiper)
			  useSwiper.slideNext()
			}
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSwiper1,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
				slidePrev,
			  slideNext
			};
		},
		methods: {
			goDan(str){
				console.log('change',str);
				this.tFlag = str;
			},
			changeFun(index, a) {
				var that = this;
				that.$data.opacityF = false;
				setTimeout(function() {
					that.$data.opacityF = true;
				}, 400)
			},
			goInfo(tFlag,bFlag)
			{
				this.tFlag = tFlag;
				this.bFlag = bFlag;
			},
			gouOutD(){
				console.log('lll');
				//获取banner
				axiosx('user/loginout.json?rand='+Math.random())
				.then((res)=>{
					let data = res.data;
					if(data.msgCode!='200')
					{
						alert(data.msg);
					}
					else
					{
						alert('退出成功');
						let routeUrl = this.$router.resolve({
							path: "/",
							query:{lang:this.systemlang}
						});
						this.userName = '';
						// console.log(routeUrl.href);
						window.open(routeUrl.href, '_self');
					}
				});
			},
			showchangepwd(){
				this.changepwdshow = true;
			},
			loginOut(){
				this.changepwdshow = false;
			},
			loginD(){
				var pwd_new = this.pwd_new;
				var pwd_newagin = this.pwd_newagin;
				if(pwd_new!=pwd_newagin){
					alert(this.langs["notsame"]);
				}else if(pwd_new==""){
					alert(this.langs["notsame"]);
				}else{
					axiosx("user/changepassword",{pwd_new:pwd_new})
					.then((res)=>{
						var data = res.data;
						if(data.msgCode!='200')
						{
							alert(data.msg);
						}
						else
						{
							alert('修改成功');
							this.changepwdshow = false;
							// let routeUrl = this.$router.resolve({
							// 	path: "/",
							// 	query:{}
							// });
							// this.userName = '';
							// window.open(routeUrl.href, '_self');
						}
					})
				}
			}
		},
		

	}
</script>


<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.3rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}
	
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.lf_tab_box {
		width: auto;
		height: 0.5rem;
		display: flex;
		align-items: center;
		margin: 0.4rem auto 0;
		justify-content: center;
	}

	.lf_tab_box div {
		width: 1.6rem;
		height: 0.5rem;
		font-size: 0.26rem;
		color: #666;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 0;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-radius: 5px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.lf_tab_box div:nth-last-of-type(1) {
		border-radius: 5px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-right: 1px solid rgba(153, 153, 153, 1);
		
		
	}
	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
		position: relative;
		z-index: 1;
		left: 0;
		border: 1px solid #C0153E!important;
	}
	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 100%;
		z-index: 99;
		padding: 0.5rem 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;
	}
	.lf_opacity_div img{
		width: 1.4rem;
		height: 1.4rem;
		border: 0.05rem solid #92AC8F;
		margin: 0 auto;
		border-radius: 50%;
	}
	.lf_opacity_div_1{
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-style: normal;
		font-size: 0.3rem;
		letter-spacing: 0.04rem;
		color: #FFFFFF;
		width: 3.36rem;
		line-height: 0.7rem;
		text-align: center;
		overflow: hidden;
		margin: 0.5rem auto 0;
		font-weight: bold;
	}
	.lf_opacity_div_1 text{
		font-size: 0.28rem;
	}
	.d_title {
		margin-top: 0.14rem;
		margin-left: 10px;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}
	.lf_match_banner_box{
		height: 4.63rem;
		width: 18.5rem;
		position: relative;
		margin: 0 auto;
	}
	.lf_match_banner_box_img{
		height: 100%;
	}
	.lf_btn_ll{
		width: 0.9rem;
		height: 0.4rem;
		background: inherit;
		background-color: rgba(255, 255, 255, 0.419607843137255);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(212, 212, 212, 1);
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		color: #fff;
		position: absolute;
		right: 0.2rem;
		top: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		cursor: pointer;
	}
	.lf_btn_l2{
		height: 0.4rem;
		background: inherit;
		background-color: rgba(255, 255, 255, 0.419607843137255);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(212, 212, 212, 1);
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		color: #fff;
		position: absolute;
		right: 1.2rem;
		top: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		cursor: pointer;
		padding: 0 0.2rem;
	}
	.lf_match_info_box_text{
		padding: 0.24rem 10px;
		font-size: 0.14rem;
		color: #333;
	}
	.lf_match_info_box_con{
		padding: 0.24rem 10px;
	}
	.lf_match_info_con{
		display: flex;
		background-color: #FAFAFA;
		box-sizing: border-box;
	}
	.lf_match_info_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
		border-right: 1px solid #F5F5F5;
		border-bottom: 1px solid #F5F5F5;
	}
	.lf_match_info_con .lf_1,.lf_match_info_con .lf_12{
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_11{
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_2{
		flex-shrink: 0;
		width: 0.5rem;
	}
	.lf_match_champion_his_box{
		padding: 0.24rem 10px;
		font-size: 0.16rem;
		
	}
	.lf_match_champion_his_tit{
		display: flex;
		background-color: #FAFAFA;
		height: 0.8rem;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		color: #333;
	}
	.lf_champion_his_1{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_2{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		position: relative;
	}
	.lf_player_card{
		position: absolute;
		left: 1.5rem;
		top: 0;
		width: 5.2rem;
		height: 2.5rem;
		box-shadow: 0px 0px 4px 2px #f1f1f1;
		display: flex;
		border-radius: 5px;
		background-color: #fff;
		z-index: 9;
	}
	.lf_player_card_img{
		margin: 0.3rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}
	.lf_player_card_con{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.lf_player_card_con_1{
		font-size: 0.24rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.2rem;
	}
	.lf_player_card_con_1 img{
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}
	.lf_player_card_con_2{
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.35rem;
		display: flex;
		align-items: center;
	}
	.lf_champion_his_3{
		width: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_3 img{
		width: 20px;
		height: 14px;
	}
	.lf_champion_his_4{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_5{
		width: 3.1rem;
		display: flex;
		flex-direction: column;
	}
	.lf_champion_his_6{
		width: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_7{
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_rd_tit{
		width: 100%;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_rd_box{
		width: 100%;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
	}
	.lf_match_champion_his_con .lf_rd_box{
		height: ;
	}
	.lf_rd_box div{
		flex-grow: 1;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_match_champion_his_con{
		display: flex;
		background-color: #FAFAFA;
		color: #999;
	}
	.lf_login_page{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.498039215686275);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.lf_login_inner_box{
		width: 6.5rem;
		height: 5rem;
		background-color: rgba(250, 250, 251, 1);
		border: none;
		border-radius: 5px;
		box-shadow: 5px 5px 50px rgb(55 55 55 / 31%);
		padding: 0.5rem;
		position: relative;
	}
	.lf_close_img{
		width: 0.3rem;
		height: 0.3rem;
		position: absolute;
		right: 0.1rem;
		top: 0.1rem;
	}
	.lf_login_inner_tit {
		font-family: '微软雅黑';
		font-weight: bold;
		font-style: normal;
		font-size: 0.28rem;
		letter-spacing: 2px;
		color: #C0153E;
		margin: 0 auto 0.1rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_login_inner_tit img{
		width: 0.36rem;
		height: 0.36rem;
		margin-right: 0.06rem;
	}
	.lf_login_inner_input_box{
		width: 100%;
		height: 0.9rem;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		margin-bottom: 0.3rem;
		margin-top: 0.4rem;
		display: flex;
		flex-direction: column;
		text-indent: 0.25rem;
		justify-content: center;
	}
	.lf_login_inner_input_box input{
		margin-left: 0.25rem;
		font-size: 0.28rem;
	}
	.lf_login_btn_B{
		width: 4rem;
		height: 0.7rem;
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0.4rem auto 0;
		cursor: pointer;
	}
	.lf_resst_password{
		color: #999;
		text-align: right;
		margin-top: 0.1rem;
		font-size: 0.2rem;
		cursor: pointer;
	}
	.lf_live_code_inner_box{
		width: 9rem;
		height: 6rem;
		background-color: rgba(250, 250, 251, 1);
		border-radius: 5px;
		padding: 0.7rem;
		position: relative;
	}
	.lf_code_img{
		width: 3.5rem;
		height: 3.5rem;
		margin: 0 auto;
	}
	.lf_clpga_div_code{
		width: 4rem;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		background-color: #fff;
		margin: 0 auto;
	}
	.lf_clpga_div{
		margin-top: 0.3rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		font-weight: bold;
		text-align: center;
	}
	.lf_clpga_div text{
		color: #C0153E;
	}
	.lf_btn2_box{
		height: 0.5rem;
	}
	.all_body_swiper {
		display: none;
		width: 7.5rem;
		margin: 0 auto;
	}
	.lf_qysc_box{
		height: 0.7rem;
		display: flex;
		align-items: center;
		width: 18.5rem;
		position: relative;
		margin: 0 auto;
		background-color: rgba(255, 241, 242, 1);
	}
	.lf_qysc_box_1{
		width: 0.7rem;
		height: 0.7rem;
	}
	.lf_qysc_box_3{
		width: 1.3rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(255, 241, 242, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(192, 21, 62, 1);
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		color: #C0153E;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 0.4rem;
		text-align: center;
	}
	.lf_qysc_box_2{
		width: 1.3rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(192, 21, 62, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 0.4rem;
		text-align: center;
	}
    @media screen and (max-width: 768px) {
		.lf_tab_tit_box_w{
			display: none!important;
		}
        .lf_login_changeL{
            font-size: 0.12rem;
        }
		.lf_live_code_inner_box{
			width: 6rem;
		}
		.lf_resst_password{
			font-size: 0.26rem;
		}
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.lf_tab_tit_box {
			align-items: unset;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
			width: 6rem;
			
		}
		.lf_tab_box {
			width: 6.5rem;
			height: 30px;
			display: flex;
			align-items: center;
			margin-top: 40px;
		}
		
		.lf_tab_box div {
			font-size: 0.24rem;
			color: #666;
			border: 1px solid rgba(153, 153, 153, 1);
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			word-break: keep-all;
			padding: 0 0.1rem;
		}
		.lf_match_banner_box{
			height: 1.78rem;
			width: 7.1rem;
			position: relative;
		}
		.lf_match_banner_box_img{
			height: 1.78rem;
		}
		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
		}
		.lf_opacity_div img{
			width: 1.5rem;
			height: 1.5rem;
			margin: 0.06rem 0.2rem;
		}
		.lf_opacity_div_1{
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.3rem;
			letter-spacing: 0.04rem;
			color: #FFFFFF;
			width: 3.36rem;
			line-height: 0.7rem;
			text-align: left;
			overflow: hidden;
			margin: 0 0;
		}
		
		.lf_12{
			display: none!important;
		}
		.lf_match_info_con{
			justify-content: flex-end!important;
		}
		.lf_match_info_con .lf_2{
			width: 0.6rem;
		}
		.lf_champion_his_5,.lf_champion_his_6{
			display: none;
		}
		.lf_player_card{
			position: absolute;
			left: 0;
			top: 0.4rem;
			width: 5.2rem;
			height: 2.5rem;
			box-shadow: 0px 0px 4px 2px #f1f1f1;
			display: flex;
			border-radius: 5px;
			background-color: #fff;
			z-index: 9;
		}
		.lf_btn_l2{
			font-size: 0.24rem;
			height: 0.34rem;
			top: unset;
			bottom: 0.2rem;
		}
		.lf_btn_ll{
			font-size: 0.24rem;
			height: 0.34rem;
			top: unset;
			bottom: 0.2rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
		.all_body_swiper {
			display: block;
		
		}
		.rightBtn{
			width: 0.7rem !important;
			height: 0.5rem;
			
			border:  1px solid rgba(153, 153, 153, 1);
		
			background: url('../../assets/images/u2786.png') no-repeat;
			background-size: 0.3rem;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			left: -0.01rem;
			cursor: pointer;
			position: relative;
			
			
			
		}
		.leftBtn{
			width: 0.7rem !important;
			height: 0.5rem;
			
			border:  1px solid rgba(153, 153, 153, 1);
			
			background: url('../../assets/images/u16135.png') no-repeat;
			background-size: 0.3rem;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			
			
			
		}
		.lf_tab_box_newSwiper{
			margin-bottom: 0.1rem;
			box-sizing: border-box;
		}
		.lf_qysc_box{
			width: 7.1rem;
		}
	}
</style>
<style>
	.all_body_swiper .swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;
	
	}
	
	.all_body_swiper .swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}
	
	.all_body_swiper  .swiper  .swiper-pagination {
		text-align: left;
		bottom: 1.5rem !important;
		padding-left: 0.5rem;
		display: none;
	}
	
	.all_body_swiper.swiper-pagination-bullet {
		
		background-color: #fff;
		border: #DBDBDB 0.01rem solid;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.02rem !important;
	}
	
	
	.lf_bannner_sss .swiper-pagination{
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 10px!important;
	}
	@media screen and (max-width: 768px) {
			.all_body_swiper .swiper-pagination {
					
				bottom: 0.2rem !important;
				padding-left: 0;
			}
			.lf_bannner_sss .swiper-pagination{
				padding-left: 0;
			}
			
	}
</style>
