<template>
	<Btn cd="ph"></Btn>
	<div v-if="playerinfo.clpgainfo" class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs.p_player}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs.p_player_en}}</div>
				</div>
			</el-col>

		</el-row>
	</div>

	<div v-if="playerinfo.clpgainfo" class="titlebox">
		<div class="box1">
			<div class="top_info">

				<div class="boxima">
					<img :src="playerinfo.clpgainfo.country_img" />
				</div>
				<div class="nameall">
					<span
						class="name">{{systemlang=='cn'?playerinfo.clpgainfo.pl_cn_name:playerinfo.clpgainfo.firstname+' '+playerinfo.clpgainfo.lastname}}
					</span>
					<span class="nameen">
						{{systemlang=='en'?playerinfo.clpgainfo.pl_cn_name:playerinfo.clpgainfo.firstname +' '+playerinfo.clpgainfo.lastname}}
						{{playerinfo.clpgainfo && playerinfo.clpgainfo.pl_type =='AMA'?'(A)':''}}
					</span>
				</div>


			</div>
			<div class="bottom_info">
				<div class="tu">
					<img src="../../assets/images/u17769.png" />
				</div>
				<div class="age">{{langs.p_age}}：
					<span style="font-weight: 600;">{{playerinfo.clpgainfo.age}}</span>
				</div>
				<div class="tu1">
					<img src="../../assets/images/u17768.png" />
				</div>
				<div class="age">{{langs.p_joinclpga}} <!--加入女子中巡：-->
					<span
						style="font-weight: 600;">{{playerinfo.clpgainfo.extend && playerinfo.clpgainfo.extend.joinclpgayear?playerinfo.clpgainfo.extend.joinclpgayear:'-'}}</span>
				</div>
			</div>

		</div>
		<div class="box2" style="overflow: unset;">
			<div class="box3" style="overflow: unset;">
				<div class="boxf">
					
					<div v-if="playerinfo.clpgainfo.clpgarank > 0" class="boxnv">
						<router-link v-if="playerinfo.clpgainfo.clpgarank > 0" target="_blank" :to="{path:'/Rankinfo',query:{pl_id:playerinfo.clpgainfo.pl_id,ranktype:'jifenrank',lang:systemlang,year:playerinfo.clpgainfo.clpgarankyear}}">
						{{langs.p_clpgapm}}<!--女子中巡排名-->
						</router-link>
					</div>
					<div v-else class="boxnv">
						{{langs.p_clpgapm}}<!--女子中巡排名-->
					</div>

					<router-link v-if="playerinfo.clpgainfo.clpgarank > 0" target="_blank" :to="{path:'/Rankinfo',query:{pl_id:playerinfo.clpgainfo.pl_id,ranktype:'jifenrank',lang:systemlang,year:playerinfo.clpgainfo.clpgarankyear}}">
					<div class="num">
						{{ playerinfo.clpgainfo.rankcode }}{{playerinfo.clpgainfo.clpgarank > 0?playerinfo.clpgainfo.clpgarank:'-'}}
						<view class="lf_year_b">{{'('+playerinfo.clpgainfo.clpgarankyear+')'}}</view>
					</div>
					</router-link>
					<div v-else class="num">
						{{ playerinfo.clpgainfo.rankcode }}{{playerinfo.clpgainfo.clpgarank > 0?playerinfo.clpgainfo.clpgarank:'-'}}
						<view class="lf_year_b">{{'('+playerinfo.clpgainfo.clpgarankyear+')'}}</view>
					</div>
					

				</div>


				<div class="boxf">
					<div v-if="playerinfo.clpgainfo.tjinfo" class="boxnv" @click="gocscj(playerinfo.clpgainfo.tjinfo.years)">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:playerinfo.clpgainfo.pl_id,bFlag:'sta',lang:systemlang,year:playerinfo.clpgainfo.tjinfo.years}}">
							{{langs.psta_match_count}}<!--参赛场次-->
						</router-link>
					</div>
					<div v-else class="boxnv">
						{{langs.psta_match_count}}<!--参赛场次-->
					</div>
					<div class="num" v-if="playerinfo.clpgainfo.tjinfo.match_count  > 0">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:playerinfo.clpgainfo.pl_id,bFlag:'sta',lang:systemlang,year:playerinfo.clpgainfo.tjinfo.years}}">
							{{playerinfo.clpgainfo.tjinfo.match_count > 0?playerinfo.clpgainfo.tjinfo.match_count:'-'}}
							
						</router-link>
						
						<view style="cursor: pointer;" class="lf_year_b">{{'('+playerinfo.clpgainfo.tjinfo.years+')'}}</view>
					</div>
					<div class="num" v-else>
						
							{{playerinfo.clpgainfo.tjinfo.match_count > 0?playerinfo.clpgainfo.tjinfo.match_count:'-'}}
							
						
						<view class="lf_year_b">{{'('+playerinfo.clpgainfo.tjinfo.years+')'}}</view>
					</div>
				</div>


				<div v-if="playerinfo.clpgainfo.supperrankcount > 0" class="boxf">
					<div v-if="playerinfo.clpgainfo.supperrankcount > 0" class="boxnv">
						<router-link target="_blank" :to="{path:'/TecStatisticsinfo',query:{topnum:'1',pl_id:playerinfo.clpgainfo.pl_id,statstype:'getfronttennum',lang:systemlang,year:'all'}}">
						{{langs.p_gjcs}} <!--冠军次数-->
						</router-link>
					</div>
					<div v-else class="boxnv">
						{{langs.p_gjcs}} <!--冠军次数-->
					</div>
					<div v-if="playerinfo.clpgainfo.supperrankcount > 0"  style="text-decoration: underline;position: relative;" class="num">
						<view @click="showgjl" style="cursor: pointer;">{{playerinfo.clpgainfo.supperrankcount > 0?playerinfo.clpgainfo.supperrankcount:'-'}}</view>
						<!-- <router-link target="_blank" :to="{path:'/TecStatisticsinfo',query:{topnum:'1',pl_id:playerinfo.clpgainfo.pl_id,statstype:'getfronttennum',lang:systemlang,year:'all'}}">
							{{playerinfo.clpgainfo.supperrankcount > 0?playerinfo.clpgainfo.supperrankcount:'-'}}
						</router-link> -->
						<div class="select-left into lf_select-left" v-if="playerinfo.clpgainfo.supperrankcount > 0 && showgujunlist" >
							<div class="select_socl"  style="width: 100%;">
								<template v-for="ggg in playerinfo.clpgainfo.supperranklist">
								<div class="select_item " title="{{ ggg.showname }}" style="width: 100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"  >
									{{ ggg.showname }}
									<!-- <div v-if="year==y.value" class="close_search">×</div> -->
								</div><!--select_item_hover-->
								
								</template>
							</div>		
						</div>
					</div>
					
					<div v-else class="num">
						{{playerinfo.clpgainfo.supperrankcount > 0?playerinfo.clpgainfo.supperrankcount:'-'}}
					</div>
					<!--TecStatisticsinfo?statstype=getfronttennum&lang=cn&year=all&topnum=1&pl_id=2397-->
				</div>

				<div v-else class="boxf">
					<div v-if="playerinfo.clpgainfo.supperrank != ''" class="boxnv">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{mt_id:playerinfo.clpgainfo.supperrankdata.mt_id,pl_id:playerinfo.clpgainfo.pl_id,bFlag:'score',lang:systemlang,year:playerinfo.clpgainfo.supperrankdata.year}}">
						{{langs.p_zhmc}} <!--最好名次-->
						</router-link>
					</div>
					<div  v-else class="boxnv">
						{{langs.p_zhmc}} <!--最好名次-->
					</div>
					<div v-if="playerinfo.clpgainfo.supperrank != ''" class="num">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{mt_id:playerinfo.clpgainfo.supperrankdata.mt_id,pl_id:playerinfo.clpgainfo.pl_id,bFlag:'score',lang:systemlang,year:playerinfo.clpgainfo.supperrankdata.year}}">
						{{playerinfo.clpgainfo.supperrank != ''?playerinfo.clpgainfo.supperrank:'-'}}
						</router-link>
					</div>
					<div v-else class="num">
						
							{{playerinfo.clpgainfo.supperrank != ''?playerinfo.clpgainfo.supperrank:'-'}}
						
					</div>
				</div>
				

				<div class="boxf">
					<div v-if="playerinfo.clpgainfo.WgwrId <= 0 || playerinfo.clpgainfo.pl_country!='CHN'" class="boxnv">
						{{langs.p_shijipm}} <!--世界排名-->
					</div>
					<div v-else class="boxnv">
						<router-link  target="_blank" :to="{path:'/Rankinfo',query:{pl_id:playerinfo.clpgainfo.pl_id,ranktype:'laolishi',lang:systemlang,year:'all'}}">
						{{langs.p_shijipm}} <!--世界排名-->
						</router-link>
					</div>
					<div v-if="playerinfo.clpgainfo.WgwrId > 0 && playerinfo.clpgainfo.pl_country=='CHN'" class="num">
						<router-link  target="_blank" :to="{path:'/Rankinfo',query:{pl_id:playerinfo.clpgainfo.pl_id,ranktype:'laolishi',lang:systemlang,year:'all'}}">
						{{playerinfo.clpgainfo.WgwrId > 0?playerinfo.clpgainfo.WgwrId:'-'}}
						</router-link>
					</div>
					<div v-else class="num">
						{{playerinfo.clpgainfo.WgwrId > 0?playerinfo.clpgainfo.WgwrId:'-'}}
					</div>
				</div>
				
				
			</div>
		</div>
		<div class="ava">
			<img v-if="playerinfo.clpgainfo.pl_photo" :src="playerinfo.clpgainfo.pl_photo" />
			<img v-else src="../../assets/images/u17762.svg" />
			
			<div v-if="playerinfo.clpgainfo && playerinfo.clpgainfo.pl_type =='AMA'" class="anwai">
				<img class="an" src="../../assets/images/am.png"  />
				<!-- <div class="an" v-if="playerinfo.clpgainfo.pl_type =='AMA'">AM</div> -->
			</div>
		</div>
		
	</div>
	<div class="lf_xhs_box" v-if="playerinfo.medial">
		
		<template  v-for="mt in playerinfo.medial">
			<div v-if="mt.is_act == '1'" class="lf_xhs_con">
				
				<img  class="lf_xhs_con_img1" :src="mt.img" alt="">
				
				<a v-if="mt.type == '1'" :href="(mt.url&&(mt.url.indexOf('http')>=0 || mt.url.indexOf('https')>=0))?mt.url:'https://'+mt.url" target="_blank" style="cursor: pointer;">
					<div v-if="mt.medial_name != '' && mt.medial_name != null" class="lf_xhs_con_text">{{systemlang=='cn'?mt.cn_name:mt.en_name}}：{{mt.medial_name}}</div>
				</a>
				<div v-else class="lf_xhs_con_text">{{systemlang=='cn'?mt.cn_name:mt.en_name}}：{{mt.medial_name}}</div>
				
				<img v-if="mt.type == '2' && mt.ercode != '' && mt.ercode != null" class="lf_xhs_con_img2" @click="hideAlertBoxI('/'+mt.ercode)" :src="'/'+mt.ercode" alt="">
			</div>
		</template>
		
	</div>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_tab_tit_box">
				<div class="lf_tab_box" style="width: auto;">

					<div @click="goInfo('match','career')" :class="bFlag=='career'|| bFlag==''?'lf_tab_box_select':''">
						{{langs.p_career}} <!--生涯-->
					</div>
					<div @click="goInfo('match','score')" :class="bFlag=='score'?'lf_tab_box_select':''">
						{{langs.p_score}} <!--成绩-->
					</div>

					<!-- 	<div @click="goInfo('ssgc','ssgc')" :class="tFlag=='ssgc'?'lf_tab_box_select':''">{{langs.Sh}}</div> -->
					<div @click="goInfo('match','sta')" :class="bFlag=='sta'?'lf_tab_box_select':''">{{langs.p_sta}}
						<!--统计-->
					</div>
					<div @click="goInfo('match','new')" :class="bFlag=='new'?'lf_tab_box_select':''">{{langs.p_new}}
						<!--新闻-->
					</div>
					<div @click="goInfo('match','video')" :class="bFlag=='video'?'lf_tab_box_select':''">
						{{langs.p_video}} <!--视频-->
					</div>

				</div>
			</div>
		</el-col>
	</el-row>
	<statistics ref="sta" v-if="bFlag=='sta' && playerinfo.clpgainfo && playerinfo.clpgainfo.pl_id"
		:sid="playerinfo.clpgainfo.pl_id+200000"></statistics>
	<playerInfo v-if="(bFlag=='career' || bFlag=='') && playerinfo.clpgainfo && playerinfo.clpgainfo.pl_id"
		:sid="playerinfo.clpgainfo.pl_id+200000"></playerInfo>
	<score v-if="bFlag=='score' && playerinfo.clpgainfo && playerinfo.clpgainfo.pl_id>0" ref="childRef" :neicheck="neicheck"
		:sid="playerinfo.clpgainfo.pl_id+200000" :gjcs="playerinfo.clpgainfo.supperrankcount"></score>
	<NewListmore v-if="bFlag== 'new' && playerinfo.clpgainfo && playerinfo.clpgainfo.pl_id" type="player" hide="1"
		:id="playerinfo.clpgainfo.pl_id" mt_id="" year="" pl_name="" mt_name=""></NewListmore>

	<VideoListmore v-if="bFlag== 'video' && playerinfo.clpgainfo && playerinfo.clpgainfo.pl_id" type="player" hide="1"
		:id="playerinfo.clpgainfo.pl_id" mt_id="" year="" pl_name="" mt_name=""></VideoListmore>

	<div style="width: 100%;height: 10px;clear: both;"></div>


	<Footer></Footer>
	<Footer1></Footer1>
	<div v-if="hideFlag" class="lf_alert_box_i" @click="hideAlertBoxI()" >
		<div class="lf_alert_box_con">
			<div class="lf_alert_box_con_1">
				<img :src="downurl" alt="" />
			</div>
			<div class="lf_alert_box_con_2">
				<text style="cursor: pointer;" @click.stop="down">{{langs.user_Clicktodownloadtheimage}}</text>
				<text style="cursor: pointer;" @click.stop="down">{{langs.user_LongpresstoscaninWeChat}}</text>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import score from "../../components/Player/score.vue";
	import statistics from "../../components/Player/statistics.vue";
	import playerInfo from "../../components/Player/info.vue";
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import NewListmore from "../../components/NewListmore.vue";
	import VideoListmore from "../../components/VideoListmore.vue";
	import { ref } from 'vue';

	const childRef = ref(null);

	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}



	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				bFlag: '',
				neicheck:false,
				systemlang: 'cn',
				playerinfo: [],
				//xhs:巡回赛新闻 match:赛站新闻
				hideFlag:false,
				downurl:'',
				showgujunlist:false
			}
		},
		props: {

		},
		watch: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			score,
			playerInfo,
			statistics,
			NewListmore,
			VideoListmore
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let pl_id = this.$route.query.pl_id;
			let bFlag = this.$route.query.bFlag;
			if (bFlag) {
				this.bFlag = bFlag;
			}

			this.getPlayerInfo(pl_id);


		},
		setup() {

		},
		methods: {
			showgjl(){
				this.goInfo('match','score',true);
				// this.showgujunlist = !this.showgujunlist;
			},
			//下载
			down(){
				let down = this.downurl;
				this.downloadImage(down)
			},
			downloadImage(url) {
				const a = document.createElement('a');
				a.href = url;
				// 分割路径并取最后一个元素作为文件名
				console.log(url);
				let $name= url.split('.').pop();
				a.download = 'down.'+$name;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			},
			gocscj(year)
			{
				this.goInfo('match','sta');
				setTimeout(()=>{
					this.$refs.sta.shows(year);
				},500);
			},
			getPlayerInfo(pl_id) {
				axios('player/player/playerinfo.json', {
					pl_id: pl_id
				}).then(res => {

					this.playerinfo = res.data.data;
				});
			},
			goInfo($ss, $flag,bool) {
				let param = this.$route.query;
				param.bFlag = $flag;
				let routeUrl = this.$router.resolve({
					path: "/playerInfo",
					query: param
				});
				window.history.replaceState(null, '', routeUrl.href);
				// window.open(routeUrl.href, '_self');
				if($ss=='match' && $flag == 'score' && bool) 
				{
					this.neicheck = true;
				}
				else
				{
					this.neicheck = false;
				}
				this.bFlag = $flag;
				
				

				setTimeout(() => {
					if($ss=='match' && $flag == 'score' && bool) 
					{
						this.$refs.childRef.changeFlag(false);
					}
				}, 1000);
				this.$refs.childRef.changeFlag(false);
				
				
				
				
			},
			hideAlertBoxI($url){
					if($url)
					{
						this.downurl = $url;
					}
					
				if (this.hideFlag == false) {
					document.body.style.overflow = "hidden";
					this.hideFlag = true
				} else {
					
					document.body.style.overflow = "visible";
					this.hideFlag = false
				}
			},
		}

	}
</script>


<style scoped="scoped">
	.titlebox {
		width: 18.5rem;
		height: 3.7rem;
		position: relative;
		margin: 0 auto;
		margin-top: 0.68rem;
	}
	.box1 {
		width: 18.5rem;
		height: 1.2rem;
		background-color: #FFF1F2;

	}

	.boxima img {
		width: 0.5rem;
	
		margin-left: 0.9rem;
		margin-top: 0.44rem;
		float: left;
	}
	.nameall{
		width: 6rem;
		margin-left: 0.2rem;
		float: left;
		overflow: hidden;
		height:1.2rem;
		display: flex;
		color: #333333;
		align-items: center;
		flex-wrap: wrap;
		line-height: 0.4rem;
		
	}
	.name {
		font-size: 0.4rem;
		font-weight: 700;
		margin-right: 0.2rem;
	}

	.nameen {
		
		font-size: 0.3rem;
		
	

	}

	.anwai {
		width:0.7rem;
		height: 0.7rem;
		position: absolute;
		right: 0;
		top: 0;
		transform: rotate(90deg);
		overflow: hidden;
	}

	.anwai .an {

		width: 0.7rem;
		height: 0.7rem;
		
	}

	.top_info {
		float: left;
		overflow: hidden;
		position: relative;

		width: 7.8rem;

		height: 1.2rem;
	}

	.bottom_info {

		float: left;
	}

	.tu {

		margin-top: 0.44rem;
		width: 0.4rem;
		height: 0.4rem;
		float: left;
	}

	.tu img {
		width: 0.4rem;
		height: 0.4rem;
	}

	.age {
		margin-left: 0.2rem;
		margin-top: 0.42rem;
		float: left;
		color: #333333;
		font-size: 0.3rem;
	}

	.tu1 {
		margin-left: 1.2rem;
		margin-top: 0.5rem;
		width: 0.3rem;
		height: 0.3rem;
		float: left;
	}

	.tu1 img {
		width: 0.3rem;
		height: 0.3rem;
	}

	.ava {
		position: absolute;
		top: 0.2rem;
		right: 0.6rem;
		overflow: hidden;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3501960784313725);
	}

	.ava img {
		width: 2.7rem;
		height: 3.2rem;
	}

	.box2 {
		width: 18.5rem;
		height: 2.5rem;
		background-color: #C0153E;
		overflow: hidden;
	}

	.box3 {
		width: 18.5rem;
		height: 2.5rem;

		overflow: hidden;
	}

	.boxf {
		width: 3.7rem;
		height: 1.2rem;
		margin-top: 0.6rem;

		color: #ffffff;
		float: left;

	}

	.boxnv {
		font-size: 0.28rem;
		text-align: center;
		height: 0.5rem;
		line-height: 0.5rem;
	}

	.num {

		font-size: 0.7rem;
		font-weight: 700;
		text-align: center;
		display: flex;
		flex-direction: column;
	}
	.lf_year_b{
		font-size: 0.22rem;
		font-weight: 400;
		position: relative;
		top: -0.1rem;
	}
	.lf_tab_box div {
	    width: 1.4rem;
	}
	.lf_xhs_box{
		display: flex;
		align-items: center;
		padding: 0.1rem auto;
		background-color: #FAFAFA;
		width: 18.5rem;
		margin: 0 auto;
	}
	.lf_xhs_con{
		width: 25%;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.lf_xhs_con_img1{
		width: auto;
		height: 0.3rem;
		margin-right: 0.1rem;
	}
	.lf_xhs_con_text{
		font-size: 0.22rem;
		color: #333;
	}
	.lf_xhs_con_img2{
		width: 0.32rem;
		height: 0.32rem;
		margin-left: 0.1rem;
	}
	.lf_alert_box_i{
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.749019607843137);
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 999;
	}
	.lf_alert_box_con{
		width: 3.34rem;
		height: fit-content;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.lf_alert_box_con_1{
		width: 3.34rem;
		height: 3.34rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(28, 28, 30, 1);
		border-radius: 0.1rem;
		margin-bottom: 0.02rem;
	}
	.lf_alert_box_con_1 img{
		width: 2.6rem;
		height: 2.6rem;
	}
	.lf_alert_box_con_2{
		width: 3.34rem;
		height: 0.72rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(28, 28, 30, 1);
		border-radius: 0.1rem;
		color: #fff;
		font-size: 0.22rem;
		display: flex;
		flex-direction: column;
	}
	.lf_select-left{
		width: 6rem;z-index: 100;
		font-weight: 400;
	}
	@media screen and (max-width: 768px) {
		.lf_select-left{
			width: 7.1rem;z-index: 100;
		}
		.titlebox {
			width: 7.5rem;
			height: auto;
			position: relative;
			margin-left: 0rem;
			margin-top: 0;
			overflow: hidden;
		}

		.box1 {
			width: 7.5rem;
			height: auto;
			background-color: #FFF1F2;
			overflow: hidden;
			padding-bottom: 0.1rem;
		}

		.top_info {
			overflow: hidden;
			width: 100%;
			
		}

		.boxima img {
			width: 0.5rem;
		
			margin-left: 0.2rem;
			margin-top: 0.44rem;
			float: left;
		}
		.nameall{
			width: 6rem;
			margin-left: 0.2rem;
			float: left;
			overflow: hidden;
		
			display: flex;
			color: #333333;
			align-items: center;
			flex-wrap: wrap;
		
		}
		.name {
			font-size: 0.4rem;
			font-weight: 700;
			margin-right: 0.2rem;
		}
		
		.nameen {
			
			font-size: 0.3rem;

		}
		
	


		.anwai {
			width: 0.7rem;
			height: 0.7rem;
		}

		.an {

			width: 0.7rem;
			height: 0.7rem;
		

		}

		.bottom_info {
			clear: both;
			width: 100%;
			overflow: hidden;
			margin-left: 0;

		}

		.tu {
			margin-left: 0.2rem;
			margin-top: 0.2rem;
			width: 0.4rem;
			height: 0.4rem;


		}

		.tu img {
			width: 0.4rem;
			height: 0.4rem;
		}

		.age {
			margin-left: 0.2rem;
			margin-top: 0.2rem;
			float: left;
			color: #333333;
			font-size: 0.3rem;
		}

		.tu1 {
			margin-left: 1.3rem;
			margin-top: 0.25rem;
			width: 0.3rem;
			height: 0.3rem;
			float: left;
		}

		.tu1 img {
			width: 0.3rem;
			height: 0.3rem;
		}

		.box2 {
			
			width: 7.5rem;
			height: 4rem;
			background-color: #C0153E;
			overflow: hidden;
		}

		.box3 {
			width: 4.6rem;
			height: 4rem;
		}

		.boxf {
			width: 2.3rem;
			height: 1.3rem;
			margin-top: 0.5rem;

			color: #ffffff;
			float: left;
		}



		.ava {
			position: absolute;
			top: 2.2rem;
			right: 0.4rem;
		}

		.ava img {
			width: 2.7rem;
			height: 3.2rem;
		}


		.lf_footer1 {
			width: 100%;
			margin: 0 auto;
		}

		.lf_footer1_tit_box {
			width: 100%;
			height: 90px;
		}


		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}


		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_xhs_box{
			display: flex;
			align-items: center;
			padding: 0.1rem auto;
			background-color: #FAFAFA;
			width: 7.5rem;
			margin: 0 auto;
			flex-wrap: wrap;
		}
		.lf_xhs_con{
			width: 50%;
			height: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: start;
		}
		.lf_xhs_con_img1{
			width: auto;
			height: 0.3rem;
			margin-right: 0.1rem;
			margin-left: 0.25rem;
		}
		.lf_xhs_con_text{
			font-size: 0.22rem;
			color: #333;
		}
		.lf_xhs_con_img2{
			width: 0.32rem;
			height: 0.32rem;
			margin-left: 0.1rem;
		}

	}
</style>