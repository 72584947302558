<template>

	<div class="tablebox">
		<div v-if="gjcs>0" class="lf_tablebox_tit" style="margin-left: 0;position: relative;top: -1px;">
			<div :class="seleftFlag?'lf_tablebox_tit_select':''" @click="changeFlag(true)">{{langs.Results_tt}}</div>
			<div :class="!seleftFlag?'lf_tablebox_tit_select':''" @click="changeFlag(false)">{{langs.Wins_tt}}</div>
		</div>
		<div class="tablehead">
			<div class="headred">
				<div v-if="seleftFlag" class="heady hand">
					<span class="bold" @click.stop="showSelect = !showSelect" style="float: left;margin-left: 0.5rem;font-size: 0.28rem;">
						{{langs.matchyf_year1}} {{year}} {{langs.matchyf_year}}
					</span>
					<img @click.stop="showSelect = !showSelect" src="../../assets/images/u2764.png" />
					
					<div class="select-left into" v-if="showSelect" >
						<div class="select_title">{{langs.matchyf_year}}</div>
						<div class="select_socl" >
							
							<template v-for="y in years">
							<div @click.stop="changYears(y.value)" class="select_item " :class="year==y.value?'select_item_hover':''"  >{{y.value}}
								<span v-if="y.matchnum==0" style="color:#999999;font-size:0.24rem;">{{langs.wucansai}}</span>
								<!-- <div v-if="year==y.value" class="close_search">×</div> -->
							</div><!--select_item_hover-->
							
							</template>
						</div>		
					</div>
				</div>
				<div v-else class="heady hand">
					<span class=""  style="float: left;margin-left: 0.5rem;font-size: 0.28rem;">
						{{seleftFlag?langs.matchyf_year3:langs.text_date}}
					</span>
				</div>
				<div v-if="seleftFlag" class="headb">{{langs.p_matchname}} <!--比赛名称--></div>
				<div v-else class="headb">{{langs.p_dgjl}} <!--比赛名称--></div>
				
				<div v-if="seleftFlag" class="headb headp">{{langs.p_paiming1}} <!--排名--></div>
				<div v-if="seleftFlag" class="headb headm">{{langs.p_prize}} <!--奖金--></div>
				<div v-if="!seleftFlag" class="headb headm">{{langs.p_chengji}} <!--成绩--></div>
				<div class="headb headr">R1</div>
				<div class="headb headr">R2</div>
				<div class="headb headr">R3</div>
				<div class="headb headr">R4</div>
				<div  class="headb headp">{{langs.p_allscore}} <!--总杆--></div>
				<div v-if="!seleftFlag" class="headb headm">{{langs.p_prize}} <!--奖金--></div>
			</div>

		<template v-for="(m,mindex) in getIndividualStatistics" >
			<div :id="m.mt_id" @click="goMatch(m.mt_id)" style="overflow: hidden;" class="headpink hand" :class="actmtid==m.mt_id?'pinkColor':''">
				<div v-if="seleftFlag" class="heady" style="text-align: center;">
					<span  class="bold" >{{m.m}} / </span> {{m.day}}
				</div>
				<div v-else class="heady" style="text-align: center;">
					{{m.y}}/{{m.m}}/{{m.day}}
				</div>
				<div class="headb hand">{{m.m_name}}</div>
				<div v-if="seleftFlag" class="headb headp">{{m.is_gan =='1'?m.rank:'&nbsp;'}}</div>
				<div v-if="seleftFlag" class="headb headm">{{m.pr_amount !=''&&m.pr_amount !='0'?m.pr_amount:'&nbsp;'}}</div>
				<div v-if="!seleftFlag" class="headb headm">{{m.topar=='0'?"E":m.topar}}</div>
				<div class="headb headr">{{m.is_gan =='1'&&m.mt_round_num>=1&&m.mt_round_num>=1?(m['R1']?m['R1']:'&nbsp;'):'/'}}</div>
				<div class="headb headr">{{m.is_gan =='1'&&m.mt_round_num>=2&&m.mt_round_num>=2?(m['R2']?m['R2']:'&nbsp;'):'/'}}</div>
				<div class="headb headr">{{m.is_gan =='1'&&m.mt_round_num>=3&&m.mt_round_num>=3?(m['R3']?m['R3']:'&nbsp;'):'/'}}</div>
				<div class="headb headr">{{m.is_gan =='1'&&m.mt_round_num>=4&&m.mt_round_num>=4?(m['R4']?m['R4']:'&nbsp;'):'/'}}</div>
				<div  class="headb headp">{{m.is_gan =='1'?m.total:'&nbsp;'}}</div>
				<div v-if="!seleftFlag" class="headb headp">{{m.pr_amount !=''&&m.pr_amount !='0'?m.pr_amount:'&nbsp;'}}</div>
			</div>
			<div v-if="actmtid==m.mt_id" class="pink_body" style="margin-bottom: 0.2rem;">
				<div class="white_body">
					<el-row>
						<el-col :xs="24" :sm="12" :md="12">
							<div class="lf_match_info_con">
								<div class="lf_1">Hole</div>
								<template v-for="h in info.hole">
									<div v-if="h.mh_id <= 9" class="lf_2">
										{{h.mh_id}}
									</div>
								</template>
							</div>
							<div class="lf_match_info_con lf_match_info_par">
								<div class="lf_1">PAR</div>
								<template v-for="h in info.hole">
									<div v-if="h.mh_id <= 9" class="lf_2">
										{{h.mh_par}}
									</div>
								</template>
							</div>

							<template  v-if="info.cj && info.cj[pl_id]" v-for="(c,ii) in info.cj[pl_id]">
								<div class="lf_match_info_score_con">
									<div class="lf_1">R{{ii}}</div>
									<template v-for="h in info.hole">
										<div v-if="h.mh_id <= 9" class="lf_2" :class="info.tp && info.tp[pl_id] && info.tp[pl_id][ii] && info.tp[pl_id][ii][h.mh_id]?info.tp[pl_id][ii][h.mh_id]:''">
											{{c[h.mh_id]}}
										</div>
									</template>
								</div>
							</template>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12">
							<div class="lf_match_info_con">
								<div class="lf_11">OUT</div>
								<template v-for="h in info.hole">
									<div v-if="h.mh_id > 9" class="lf_22">
										{{h.mh_id}}
									</div>
								</template>
								<div class="lf_11">
									IN
								</div>
							</div>

							<div class="lf_match_info_con lf_match_info_par">
								<div  class="lf_11 bold">{{jsPar('OUT')}}</div>
								<template v-for="h in info.hole">
									<div v-if="h.mh_id > 9" class="lf_22">
										{{h.mh_par}}
									</div>
								</template>
								<div class="lf_11 bold">
									{{jsPar('IN')}}
								</div>
							</div>

							<template  v-if="info.cj && info.cj[pl_id]" v-for="(c,ii) in info.cj[pl_id]">
								<div class="lf_match_info_score_con" >
									<div class="lf_11">{{jsParScore(pl_id,ii,'OUT')}}</div>
									<template v-for="h in info.hole">
										<div v-if="h.mh_id > 9" :class="info.tp && info.tp[pl_id] && info.tp[pl_id][ii] && info.tp[pl_id][ii][h.mh_id]?info.tp[pl_id][ii][h.mh_id]:''" class="lf_22">
											{{c[h.mh_id]}}
										</div>
									</template>
									<div class="lf_11 bold">
										{{jsParScore(pl_id,ii,'IN')}}
									</div>
								</div>
							</template>



						</el-col>
					</el-row>
				</div>
				<div style="clear: both;"></div>
				<div class="gray_body"  style="border-bottom: unset;">
					<div class="lf_match_info_con_new">
						<div class="lf_33">
							{{langs.p_kqjl}} <!--开球距离(yds)-->
						</div>
						<div class="lf_33">
							{{langs.p_sqdv}} <!--上球道率(%)-->
						</div>
						<div class="lf_33">
							{{langs.p_sglv}} <!--上果岭率(%)-->
						</div>
						<div class="lf_33">
							{{langs.p_wdbsglv}} <!--未标上果岭救球率(%)-->
						</div>
						<div class="lf_33">
							{{langs.p_bsllpjt}} <!--标上果岭平均推杆-->
						</div>
						<div class="lf_33">
							{{langs.p_skjql}} <!--沙坑救球率(%)-->
						</div>
					</div>
				</div>
				<div class="grayw_body">
					<div class="lf_match_info_con_new lf_match_info_con_new_two">
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['driving_distance']?info.single_tj['driving_distance']:'-'}}
						</div>
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['kaiqiuzhunquelv']?info.single_tj['kaiqiuzhunquelv']:'-'}}
						</div>
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['shangguolinglv']?info.single_tj['shangguolinglv']:'-'}}
						</div>
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['weishangguolinglv']?info.single_tj['weishangguolinglv']:'-'}}
						</div>
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['pingjuntuigan']?info.single_tj['pingjuntuigan']:'-'}}
						</div>
						<div class="lf_33">
							{{info.single_tj&&info.single_tj['jiuqiulv']?info.single_tj['jiuqiulv']:'-'}}
						</div>
					</div>
				</div>
			</div>
		</template>
		
		
		<!--填写空数据样式-->
		<div v-if="show=='1' && getIndividualStatistics.length <= 0">
			<empty></empty>
		</div>
			
		</div>

	</div>
	
	<div class="tablebox_mobile">
		<div class="lf_tablebox_tit">
			<div :class="seleftFlag?'lf_tablebox_tit_select':''" @click="changeFlag(true)">{{langs.Results_tt}}</div>
			<div :class="!seleftFlag?'lf_tablebox_tit_select':''" @click="changeFlag(false)">{{langs.Wins_tt}}</div>
		</div>
		<div class="select-back" v-if="showSelect" @click.stop="showSelect = !showSelect"></div>
		<div class="select-left into" style="z-index:99999" v-if="showSelect">
			<div class="select_title"  @click.stop="showSelect = !showSelect"> {{langs.matchyf_year}}</div>
			<div class="select_socl">
				<template v-for="y in years">
				<div @click.stop="changYears(y.value)" class="select_item " :class="year==y.value?'select_item_hover':''"  >{{y.value}}<span v-if="y.matchnum==0" style="color:#999999;font-size:0.24rem;">{{langs.wucansai}}</span></div>
				</template>
			</div>		
		</div>
		<div class="tablehead">
			<div class="headred">
				<div v-if="seleftFlag" class="heady" >
					<span @click.stop="showSelect = !showSelect" class="bold"  style="float: left;margin-left: 0.7rem;">{{year}} {{langs.matchyf_year}}</span>
					<img @click.stop="showSelect = !showSelect"  src="../../assets/images/u2764.png" />
				</div>
				<div v-else class="heady" >
					<span  class="bold"  style="float: left;margin-left: 0.7rem;">{{langs.matchyf_year}}</span>
					
				</div>

				<div v-if="seleftFlag" class="headb">{{langs.p_matchname}} <!--比赛名称--></div>
				<div v-else class="headb">{{langs.p_dgjl}} <!--比赛名称--></div>
	
			</div>

			<template v-for="(m,mindex) in getIndividualStatistics" >
				<div :id="'m'+m.mt_id" @click="goMatch(m.mt_id)" :class="actmtid==m.mt_id?'pinkColor':''" class="headpink">
					<div v-if="seleftFlag" class="heady" style="text-align: center;">
						<span  class="bold">{{m.m}} / </span> {{m.day}}
					</div>
					<div v-else class="heady" style="text-align: center;">
						 {{m.y}}/{{m.m}}/{{m.day}}
					</div>
					<div class="headb">{{m.m_name}}</div>
		
				</div>
				<div  v-if="actmtid==m.mt_id" class="mobile_add">
					<div class="add_top">
						<div v-if="seleftFlag" class="add_item">{{langs.p_paiming}} <!--排名--></div>
						<div v-else class="add_item">{{langs.p_chengji}} <!--排名--></div>
						<div v-if="seleftFlag" class="add_item large">{{m.is_gan =='1'?m.rank:'&nbsp;'}}</div>
						<div v-else class="add_item large">{{m.topar=='0'?'E':m.topar}}</div>
						<div class="add_item">{{langs.p_prize}}</div>
						<div class="add_item large">{{m.pr_amount !=''&&m.pr_amount !='0'?m.pr_amount:'&nbsp;'}}</div>
					</div>
					<div class="add_bottom">
						<div class="add_bottom_top">
							<div class="add_bottom_item">R1</div>
							<div class="add_bottom_item">R2</div>
							<div class="add_bottom_item">R3</div>
							<div class="add_bottom_item">R4</div>
							<div class="add_bottom_item">{{langs.p_allscore}} <!--总杆--></div>
						</div>
						<div class="add_bottom_bottom">
							<div class="add_bottom_item">{{m.is_gan =='1'&&m.mt_round_num>=1?(m['R1']?m['R1']:'&nbsp;'):'/'}}</div>
							<div class="add_bottom_item">{{m.is_gan =='1'&&m.mt_round_num>=2?(m['R2']?m['R2']:'&nbsp;'):'/'}}</div>
							<div class="add_bottom_item">{{m.is_gan =='1'&&m.mt_round_num>=3?(m['R3']?m['R3']:'&nbsp;'):'/'}}</div>
							<div class="add_bottom_item">{{m.is_gan =='1'&&m.mt_round_num>=4?(m['R4']?m['R4']:'&nbsp;'):'/'}}</div>
							
							<div class="add_bottom_item">{{m.is_gan =='1'?m.total:'&nbsp;'}}</div>
						</div>
					</div>
				</div>


						<swiper :modules="modules" :autoplay="false" :pagination="{ clickable: true }"
							@swiper="onSwiper" @slideChange="onSlideChange" v-if="actmtid==m.mt_id" :style="'height:'+myswiperH+'px'" class="swiper_all" >
							<swiper-slide ref="myswiperdiv" class="swiper_item">
						<div class="pink_body">
							<div class="white_body">
								<el-row>
									<el-col :xs="24" :sm="24" :md="24">
										<div class="lf_match_info_con">
											<div class="lf_1 bold">Hole</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id <= 9" class="lf_2 redColor ">
													{{h.mh_id}}
												</div>
											</template>
											<div class="lf_1 bold">OUT</div>
										</div>

										<div class="lf_match_info_con lf_match_info_par">
											<div class="lf_1 bold">PAR</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id <= 9" class="lf_2 redColor ">
													{{h.mh_par}}
												</div>
											</template>
											<div class="lf_1 bold">{{jsPar('OUT')}}</div>
										</div>

										<template  v-if="info.cj && info.cj[pl_id]" v-for="(c,ii) in info.cj[pl_id]">
										<div class="lf_match_info_score_con">
											<div class="lf_1 bold">R{{ii}}</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id <= 9" class="lf_2" :class="info.tp && info.tp[pl_id] && info.tp[pl_id][ii] && info.tp[pl_id][ii][h.mh_id]?info.tp[pl_id][ii][h.mh_id]:''">
													{{c[h.mh_id]}}
												</div>
											</template>
											<div class="lf_1 bold">{{jsParScore(pl_id,ii,'OUT')}}</div>
										</div>
										</template>
										
									</el-col>
									
								</el-row>
							</div>
							<div style="clear: both;"></div>
							<div class="gray_body">
								<div class="lf_match_info_con_new">
									<div class="lf_33">
										{{langs.p_kqjl}} <!--开球距离(yds)-->
									</div>
									<div class="lf_33">
										{{langs.p_sqdv}} <!--上球道率(%)-->
									</div>
									<div class="lf_33">
										{{langs.p_sglv}} <!--上果岭率(%)-->
									</div>
								</div>
							</div>
							<div class="grayw_body">
								<div class="lf_match_info_con_new_two">
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['driving_distance']?info.single_tj['driving_distance']:'-'}}
									</div>
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['kaiqiuzhunquelv']?info.single_tj['kaiqiuzhunquelv']:'-'}}
									</div>
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['shangguolinglv']?info.single_tj['shangguolinglv']:'-'}}
									</div>
									
								</div>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide class="swiper_item" >
						<div class="pink_body">
							<div class="white_body">
								<el-row>
									<el-col :xs="24" :sm="24" :md="24">
										<div class="lf_match_info_con">
											<div class="lf_1 bold">Hole</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id > 9" class="lf_2 redColor ">
													{{h.mh_id}}
												</div>
											</template>
											<div class="lf_1 bold">IN</div>
										</div>

										<div class="lf_match_info_con lf_match_info_par">
											<div class="lf_1 bold">PAR</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id > 9" class="lf_2 redColor ">
													{{h.mh_par}}
												</div>
											</template>
											<div class="lf_1 bold">{{jsPar('IN')}}</div>
										</div>

										<template  v-if="info.cj && info.cj[pl_id]" v-for="(c,ii) in info.cj[pl_id]">
										<div class="lf_match_info_score_con">
											<div class="lf_1 bold">R{{ii}}</div>
											<template v-for="h in info.hole">
												<div v-if="h.mh_id > 9" class="lf_2" :class="info.tp && info.tp[pl_id] && info.tp[pl_id][ii] && info.tp[pl_id][ii][h.mh_id]?info.tp[pl_id][ii][h.mh_id]:''">
													{{c[h.mh_id]}}
												</div>
											</template>
											<div class="lf_1 bold">{{jsParScore(pl_id,ii,'IN')}}</div>
										</div>
										</template>
										
									</el-col>
									
								</el-row>
							</div>
							<div style="clear: both;"></div>
							<div class="gray_body">
								<div class="lf_match_info_con_new">
									<div class="lf_33">
										{{langs.p_wdbsglv}} <!--未标上果岭救球率(%)-->
									</div>
									<div class="lf_33">
										{{langs.p_bsllpjt}} <!--标上果岭平均推杆-->
									</div>
									<div class="lf_33">
										{{langs.p_skjql}} <!--沙坑救球率(%)-->
									</div>
								</div>
							</div>
							<div class="grayw_body">
								<div class="lf_match_info_con_new_two">
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['weishangguolinglv']?info.single_tj['weishangguolinglv']:'-'}}
									</div>
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['pingjuntuigan']?info.single_tj['pingjuntuigan']:'-'}}
									</div>
									<div class="lf_33">
										{{info.single_tj&&info.single_tj['jiuqiulv']?info.single_tj['jiuqiulv']:'-'}}
									</div>
									
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</template>
			
		</div>
	
	</div>
	
	

</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';
	
	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
// @ is an alias to /src
import axios from '../../axios/request';
	import empty from "../../components/empty.vue";
import {
	getSystemLang,
	getLangAll
} from "../../lang";

var enddata = (new Date()).getFullYear();
var years = [];
for (var i = enddata; i >= 2009; i--) {
	years.push({ value: i, label: i });
}



//自动轮播的配置
export default {
	data() {
		return {
			langs: '',
			systemlang: 'cn',
			input: '',
			value: '',
			years: years,
			year: enddata,
			showSelect:false,
			matchs: [],
			mt_id: '',
			pl_name: '',
			banner: {},
			wonderful: {},
			xhs: {},
			match: {},
			//xhs:巡回赛新闻 match:赛站新闻
			//球员代码添加 
			sk: '',//搜索类型 p:奖金榜 n:名称搜索 l:字母搜索 c:国家搜索
			sv: '',   //搜索值
			pl_id: '', //球员ID
			prizeYears: JSON.parse(JSON.stringify(years)),
			prizeY: enddata,
			prizeList: [], //奖金榜列表
			prizeParam: {},//奖金榜参数
			lettList: [], //字母搜索列表
			lettParam: {},//字母搜索参数
			coutryList:[],
			country:'',
			letter:'',//字母
			searchkey:'',//搜索字段
			acttype:"playerinfo",
			playerinfo:[],
			getCareer:[], //职业生涯
			getIndividualStatistics:[],
			getIndividualStatisticsTj:[],
			order:'t',
			actmtid:'',  //活动的比赛ID
			info:{}, //成绩信息
			myswiperH:300,
			stats:[],
			show:false,
			loading:false,
			seleftFlag:true
		}
	},
	props: {
		sid:String,
		gjcs:Number,
		neicheck:Boolean
	},
	watch: {
		showSelect(val) {
			if(val){
				document.body.style.overflow = 'hidden';
				this.$nextTick(() => {

				const parent = document.querySelector(`.select_item_hover`).offsetTop;
				document.querySelector(`.select_socl`).scrollTo(0, parent - 100)
				});
			}else{
				document.body.style.overflow = 'unset';
			}
		},
	},
	name: 'Home',
	components: {

		empty,
		Swiper,
		SwiperSlide,
	},
	mounted() {
		if(this.neicheck)
		{
			this.seleftFlag = false;
			this.getIndividualStatisticsDetailGJ('getIndividualStatistics',this.pl_id,this.year,(year)=>{});
		}
		else
		{
			this.seleftFlag = true;
			this.getIndividualStatisticsDetail('getIndividualStatistics',this.pl_id,this.year,(year)=>{
				console.log('sssssssss',year,'sss')
				this.changYears(year);
				
			
			});
			this.getIndividualStatisticsDetail_all('getIndividualStatistics',this.pl_id);
		}
		
	},
	created() {
		

		this.systemlang = getSystemLang();
		this.langs = getLangAll(this.systemlang);
		// sk:'',//搜索类型 sk:'',//搜索类型 p:奖金榜 n:名称搜索 l:字母搜索
		// sv:'',   //搜索值
		// pl_id:'' //球员ID
		console.log('ssssssssssssssss',this.sid);
		let pl_id = this.pl_id =  this.sid;
		let year = (new Date()).getFullYear();
		year = this.$route.query.year ? this.$route.query.year : year;
		let page = '1';
		//:TODO test
		// year = 2024;
		this.prizeY = year;
		this.year = year;

		this.actmtid = this.$route.query.mt_id ? this.$route.query.mt_id : '';
		if(this.actmtid > 0)
		{
			this.getPlayerScore(this.actmtid,()=>{
				var currentUrl = window.location.href;
				if(currentUrl.indexOf('#') > -1) {
					
				}
				else
				{
					// 将 # 添加到 URL
					var newUrl = currentUrl + "#"+this.actmtid;
					window.location.href = newUrl;
					window.reload();
				}
				
			});
		}


		document.body.addEventListener('click', (e) => {
			console.log('sssssssss')
			this.showSelect = false;
		});
		// this.getIndividualStatisticsDetail('getIndividualStatistics',this.pl_id,this.year,(year)=>{
		// 		console.log('sssssssss',year,'sss')
		// 		this.changYears(year);
		// });
		// this.getIndividualStatisticsDetail_all('getIndividualStatistics',this.pl_id);
		
		// this.getIndividualStatistics1();
	},
	setup() {
		const onSwiper = (swiper) => {
			console.log(swiper);
		};
		const onSlideChange = () => {
			// console.log('slide change123123123', e.activeIndex);
		};
		const autoplayOptions = {
			delay: 5000,
			disableOnInteraction: true,
			loop: false,
			pauseOnMouseEnter: true,
			reverseDirection: true
		};
		return {
			onSwiper,
			onSlideChange,
			autoplayOptions,
			modules: [Pagination, Autoplay],
		};
	},
	methods: {
		changeFlag(flag)
		{
			this.seleftFlag = flag;
			if(this.seleftFlag)
			{
				this.getIndividualStatisticsDetail('getIndividualStatistics',this.pl_id,this.year,(year)=>{
					this.changYears(year);
				});
				// this.getIndividualStatisticsDetail('getIndividualStatistics',this.pl_id,this.year)
				this.showSelect = false;
				this.actmtid = '';
			}
			else
			{
				this.getIndividualStatisticsDetailGJ('getIndividualStatistics',this.pl_id)
				this.showSelect = false;
				this.actmtid = '';
			}
			
		},
		changYears(e){
			this.year = e;
			this.getIndividualStatisticsDetail('getIndividualStatistics',this.pl_id,this.year)
			this.showSelect = false;
			this.actmtid = '';
		},
		goDeitail(type,order)
		{
			this.acttype = type;
			if(type == 'getCareer')
			{
				this.getPlayerDetai(type,this.pl_id);
			}
			else if(type == 'getIndividualStatistics')
			{
				this.order = order;
				this.getIndividualStatisticsDetail(type,this.pl_id);
			}
		},
		getIndividualStatisticsDetailGJ(type,pl_id,callback){
			axios('player/player/deitail.json',{pl_id:pl_id,type:'getPlayerScoreGJ',year:this.year,order:this.order})
			.then((res)=>{
				
				if(type == 'getIndividualStatistics')
				{
					this.getIndividualStatistics = res.data.data;
					this.getIndividualStatisticsTj = res.data.tj;
				}
				this.show = '1';
				
			});
		},
		getIndividualStatisticsDetail(type,pl_id,year,callback){
			axios('player/player/deitail.json',{pl_id:pl_id,type:'getPlayerScore',year:this.year,order:this.order})
			.then((res)=>{
				
				if( res.data.data.length <=0)
				{
					callback&&callback(this.year-1);
				}
				if(type == 'getIndividualStatistics')
				{
					this.getIndividualStatistics = res.data.data;
					this.getIndividualStatisticsTj = res.data.tj;
				}
				this.show = '1';
				
			});
		},
		getIndividualStatisticsDetail_all(type,pl_id){
			axios('player/player/deitail.json',{pl_id:pl_id,type:'getPlayerScore_all',order:this.order})
			.then((res)=>{
				var list = res.data.data;
				for(var k in years){
					if(list[years[k].value]==undefined){
						years[k].matchnum = 0;
					}else{
						years[k].matchnum = list[years[k].value];
					}
				}
			});
		},
		getCareerSinge($type)
		{
			let data = 0;
			let getCareer = this.getCareer;
			for(let i in getCareer)
			{
				data += getCareer[i][$type]?parseFloat(getCareer[i][$type]):0;
				
			}
			if($type == 'amount')
			{
				return this.formatNum(data);
			}
			return parseInt(data);
			
		},
		formatNum(num) {
			if(!num){
				return '';
			}
			num = parseInt(num);
			const numStr = num.toString().split('.'); //先转为字符串，再分开整数和小数部分
			let numInt = numStr[0]; // 整数部分
			let numDec = numStr.length > 1 ? '.' + numStr[1] : '.'; // 小数部分，只有原数字存在小数点的时候，分割完长度才会大于1
			while (3 - numDec.length) numDec += '0';  //补0，只有整数的补2个，有一位小数的补1个
			let resultInt = ''; //存储整数部分处理结果
			while (numInt.length > 3) {     //当剩余整数部分长度大于3时继续处理
				resultInt = ',' + numInt.slice(-3) + resultInt;  //把后三位截出来，和处理结果拼在一起，前面加逗号
				numInt = numInt.slice(0, -3); // 剩下的部分是除去刚刚截掉的3位的部分
			}
			return numInt + resultInt ; //结果是“剩余的不足3位的整数”+“处理好的整数结果”+“小数部分”
		},
		getPlayerDetai(type,pl_id,year){
			axios('player/player/deitail.json',{pl_id:pl_id,type:type})
			.then((res)=>{
				if(type == 'getCareer')
				{
					this.getCareer = res.data.data.data;
				}
				
			});
		},
		//展开比赛
		goMatch(e){ 
			var that=this;
			if(this.actmtid == e)
			{
				this.actmtid = '';
			}
			else
			{
				
				this.getPlayerScore(e,()=>{
					
					this.$nextTick(() => {
						that.myswiperH=that.$refs.myswiperdiv[0].$el.clientHeight+30;
						console.log('12123123123-',this.$refs.myswiperdiv[0].$el.clientHeight)
					})
					
				});
			}
			
		},
		getPlayerScore(mt_id,callback){
			///player/player/playerscore.json?pl_id=200004&mt_id=31888
			this.actmtid = mt_id;
			axios('player/player/playerscore.json',{pl_id:this.pl_id,mt_id:mt_id})
			.then((res)=>{
				this.info = res.data.data;
				// console.log('info',this.info);
				callback&&callback();
			});
		},
			jsPar($str)
			{
				if(!(this.info && this.info.hole))
				{
					return '-';
				}
				var  rsult = 0;
				if($str == 'OUT')
				{
					let hole = this.info.hole;
					for(let i in hole)
					{
						if(hole[i].mh_par && hole[i].mh_id <= 9)
						{
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if($str == 'IN')
				{
					let hole = this.info.hole;
					for(let i in hole)
					{
						if(hole[i].mh_par && hole[i].mh_id > 9)
						{
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if(rsult == 0)
				{
					return '--';
				}
				else
				{
					return rsult;
				}

			},
		jsParScore($pl,$rid,$str)
			{
				if(!(this.info && this.info.cj && this.info.cj[$pl] && this.info.cj[$pl][$rid]))
				{
					return '-';
				}

				var  rsult = 0;
				if($str == 'OUT')
				{
					let hole = this.info.cj[$pl][$rid];
					for(let i in hole)
					{
						if(hole[i] && i <= 9)
						{
							rsult += parseInt(hole[i]);
						}
					}
				}

				if($str == 'IN')
				{
					let hole = this.info.cj[$pl][$rid];
					for(let i in hole)
					{
						if(hole[i] && i > 9)
						{
							rsult += parseInt(hole[i]);
						}
					}
				}

				if(rsult == 0)
				{
					return '--';
				}
				else
				{
					return rsult;
				}
			},
		changeplayer(){
			this.acttype = "playerinfo";
			this.loading = true;
			axios('player/player/deitail.json',{pl_id:this.pl_id,type:"playerinfo",year:this.year})
			.then((res)=>{
				this.playerinfo = res.data.data.data;
				this.loading = false;
			});
		},
		getIndividualStatistics1(){
			this.acttype = "getIndividualStatistics1";
			this.loading = true;
			axios('player/player/deitail.json',{pl_id:this.pl_id,type:"getIndividualStatistics1"})
			.then((res)=>{
				var list = res.data.data.data;
				for(var key in list){
					if(list[key].mt_tour == this.year){
						for(var k in list[key]){
							if(k!="rank"){
								list[key][k] = Number(list[key][k]).toFixed(1);
							}
						}
						this.stats = list[key];
					}
				}
				this.loading = false;
			});
		}
	}

}
</script>

<style scoped lang="less">
	.tablebox {
		width: 18.5rem;
		background-color: #FAFAFA;
		margin-left: 0.36rem;
		margin-top: 0.4rem;
		padding-bottom: 0.4rem;
		padding-top: 0.01rem;
	}

	.tablehead {
		width: 17.7rem;
		margin-left: 0.4rem;
		margin-top: 0.4rem;
		background-color: #fff;
			// min-height: 5rem;
	}
	.select-left{
		top:0.9rem;
		text-align: left;
		z-index: 99;
	}
	
	.pinkColor{
		background-color: #FFF1F2 !important;
		
	}
	.headred {
		width: 17.7rem;
		background-color: #C0153E;
		height: 0.8rem;
		color: #fff;
		font-size: 0.24rem;
		line-height: 0.8rem;
		position: relative;
	}
	.headred .heady{
		font-size: 0.24rem;
		color: #fff;
	}


	.heady {
		width: 3.1rem;
		float: left;
		font-size: 0.28rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000000;
	}

	.heady img {
		width: 0.36rem;
		height: 0.36rem;
		margin-left: 0.1rem;
	}

	.headb {
		width: 4.5rem;
		float: left;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: normal;
		height: 100%;
	}

	.headp {
		width: 1.7rem;

	}

	.headm {
		width: 2rem;
	}

	.headr {
		width: 1rem;
	}

	.headpink {
		width: 17.7rem;
		
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.28rem;
	}
	.headpink:nth-child(odd) {
	            background: rgba(250,250,250);
	 }
	.headpink:hover{
		background-color: #FFF1F2;
	}

	.p_tablebox {
		display: none;
	}
	.lf_match_info_score_con {
		display: flex;
		box-sizing: border-box;
	}

	.lf_match_info_score_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.73rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #333;
	}


	.lf_match_info_score_con .lf_b {
		color: #B6081D;
	}

	.lf_match_info_score_con .lf_par {
		color: #333;
	}

	.lf_match_info_score_con .lf_e {
		color: #2C2AE3;
	}



	.pink_body {
		width: 17.12rem;
		background-color: #FFF1F2;
		border-radius: 8px;
		margin: 0 auto;
		margin-top: 0.24rem;
		overflow: hidden;
		padding-bottom: 0.2rem;
	}

	.white_body {
		width: 16.72rem;
		background-color: #fff;
		border-radius: 8px;
		margin: 0 auto;
		margin-top: 0.2rem;
	}

	.gray_body {
		width: 16.72rem;
		background-color: rgba(247, 247, 247);
		margin: 0 auto;
		margin-top: 0.2rem;
		border-bottom: 1px solid #dbdbdb;
	}

	.grayw_body {
		width: 16.72rem;
		height: 0.8rem;
		line-height: 0.8rem;
		background-color: #fff;
		margin: 0 auto;
	}
	.lf_match_info_con{
		display: flex;
	}
	.lf_match_info_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.73rem;
		height: 0.5rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		color: #C0153E;
		border-right: 1px solid #f7f7f7;
	}
	.lf_match_info_con div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_match_info_con div:nth-last-of-type(1).lf_2{
		border-right: unset;
		border-right: 1px solid #f7f7f7;
	}
	.lf_match_info_par div:nth-last-of-type(1).lf_2{
		border-right: unset;
	
	}
	.lf_match_info_par div{
		border-right: unset;
	}
	.lf_match_info_con .lf_1{
		width: 1.3rem;
		font-weight: bold;
		flex-grow: 1;
	}
	.lf_match_info_con .lf_2{
		width: .73rem;
		font-weight: bold;
	}
	.lf_match_info_con .lf_11{
		width: 1.2rem;
		font-weight: bold;
	}
	.lf_match_info_con .lf_22{
		width: .73rem;
		font-weight: bold;
	}
	
	.lf_match_info_par{
		background-color: #f7f7f7;
	}
	.lf_match_info_par div{
		color: #666!important;
	}
	.lf_match_info_score_con div{
		height: 0.8rem;
	}
	.lf_match_info_score_con .lf_1{
		width: 1.3rem;
		font-weight: bold;
		flex-grow: 1;
		color: #C0153E;
		font-size: 0.24rem;
	}
	.lf_match_info_score_con .lf_11{
		width: 1.2rem;
		font-weight: bold;
		color: #333;
		background-color: #FFF1F2;
	}
	.lf_match_info_con_new {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 0.6rem;
	}
	
	.lf_match_info_con_new div {
		width: 33.33%;
		height: 0.41rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
	}
	.lf_match_info_con_new_two {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
	}
	
	.lf_match_info_con_new_two div {
		width: 33.33%;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.28rem;
		color: #333;
		border-right: 1px solid #dbdbdb;
	}
	.lf_match_info_con_new_two div:nth-last-of-type(1){
		border-right: unset;
	}
	.bold{
		font-weight: bold;
	}
	.tablebox_mobile{
		display: none;
	}
	.select_item{
		position: relative;
	}
	.select_item span{
		position: absolute;
		right: 0.2rem;
		color: #999!important;
		font-size: 0.24rem;
	}
	.lf_tablebox_tit{
		width: 18.5rem;
		background-color: #fff;
		margin-left: 0.36rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_tablebox_tit div{
		cursor: pointer;
		width: 1.6rem;
		height: 0.4rem;
		background: inherit;
		background-color: rgba(250, 250, 250, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		color: #666666;
		margin: 0 1px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Arial Normal', 'Arial';
	}
	.lf_tablebox_tit .lf_tablebox_tit_select{
		color: #C0153E;
		text-decoration: underline;
	}
	@media screen and (max-width: 768px) {
		.tablebox{
			display: none;
		}
		.tablebox_mobile {
			display: block;
			width: 7.5rem;
			overflow: hidden;
			background-color: #FAFAFA;
			margin-top: 0.4rem;
			// padding-bottom: 0.4rem;
		}
	
		.tablehead {
			width: 7.1rem;
			margin-left: 0.2rem;
			overflow: hidden;
			margin-top: 0.3rem;
			background-color: #fff;
		}
	
	
	
	
		.headred {
			width: 7.1rem;
			background-color: #C0153E;
			height: 0.7rem;
			color: #fff;
			font-size: 0.24rem;
			line-height: 0.7rem;
			display: flex;
			align-items: center;
		}
		.headpink {
			width: 7.1rem;
			display: flex;
			align-items: center;
			height: 0.8rem;
			line-height: 0.8rem;
			font-size: 0.28rem;
		}
		.heady {
			width: 2.6rem;
			float: left;
			font-size: 0.28rem;
			text-align: center;
		}
	
		.heady img {
			width: 0.36rem;
			height: 0.36rem;
			margin-left: 0.1rem;
		}
	
		.headb {
			width: 4.5rem;
			float: left;
			font-size: 0.24rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: normal;
		}
	
		.mobile_add {
			width: 6.8rem;
			overflow: hidden;
			margin: 0 auto;
	
			margin-top: 0.2rem;
		}
	
		.add_top {
			width: 6.8rem;
			overflow: hidden;
			height: 0.5rem;
			background-color: #FFF1F2;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
	
		}
	
		.add_item {
			width: 1.7rem;
			text-align: center;
			line-height: 0.5rem;
			height: 0.5rem;
			border-right: #fff 0.01rem solid;
			font-size: 0.24rem;
			color: #666666;
		}
	
		.large {
			font-size: 0.28rem;
			color: #000000;
		}
	
		.add_bottom {
			width: 6.8rem;
			overflow: hidden;
			height: 1rem;
	
	
		}
	
		.add_bottom_top {
			width: 6.8rem;
			overflow: hidden;
			height: 0.5rem;
			background-color: #C0153E;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.24rem;
			color: #fff;
		}
	
		.add_bottom_bottom {
			width: 6.8rem;
			overflow: hidden;
			height: 0.5rem;
			background-color: #FFF1F2;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.28rem;
			color: #000000;
		}
	
		.add_bottom_item {
			width: 1.44rem;
			text-align: center;
			line-height: 0.5rem;
			height: 0.5rem;
			border-right: #fff 0.01rem solid;
		}
		.swiper_all{
			width: 100%;
			height: fit-content;
			overflow: hidden;
		}
		.swiper_item{
			width: 100%;
			height: auto;
		}
		.pink_body {
			width: 6.8rem;
			
			background-color: #FFF1F2;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.24rem;
			overflow: hidden;
			padding-bottom: 0.2rem;
		}
		
		.white_body {
			width: 6.4rem;
			background-color: #fff;
			border-radius: 0.08rem;
			margin: 0 auto;
			margin-top: 0.2rem;
		}
		
	
	
	
		
	
	
		.lf_match_info_con {
			display: flex;
			box-sizing: border-box;
			border-bottom: 0.01rem solid #EFEFEF;
		}
	
	
		.lf_match_info_con div {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: '微软雅黑';
			font-style: normal;
			font-size: 0.24rem;
			height: 0.5rem;
		}
		 .lf_match_info_con .lf_21,
		.lf_match_info_con .lf_22 {
			width: 0.5rem;
			
			color: #C0153E;
			flex-grow: 1;
			flex-shrink: 0;
		}
		.lf_match_info_con .lf_22 {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			width: 0.5rem;
		}
		
		
		.lf_match_info_score_con {
			display: flex;
			box-sizing: border-box;
		}
		
		
		.lf_match_info_score_con div {
			display: flex;
			align-items: center;
			justify-content: center;
	
			font-family: '微软雅黑';
			
			font-style: normal;
			font-size: 0.28rem;
			color: #333;
			height: 0.8rem;
		}
		.lf_match_info_score_con .lf_21,
		.lf_match_info_score_con .lf_22 {
			width: 0.5rem;
			height: 0.8rem;
			flex-grow: 1;
			flex-shrink: 0;
		}
		.lf_match_info_score_con .lf_1:nth-last-of-type(1){
			background-color: #FFF1F2;
			color: #333;
		}
		.gray_body {
			width:6.4rem;
			background-color: rgba(247, 247, 247);
			margin: 0 auto;
			margin-top: 0.2rem;
			height: 0.6rem;
		}
		
		.grayw_body {
			width:6.4rem;
			height: 0.8rem;
			line-height: 0.8rem;
			background-color: #fff;
			margin: 0 auto;
		}
		.lf_match_info_con_new {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 0.6rem;
			line-height: normal;
		}
		
		.lf_match_info_con_new div {
			width: 33.33%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.24rem;
			color: #666666;
			text-align: center;
		}
		.lf_match_info_con_new_two {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 0.8rem;
			line-height: 0.8rem;
		}
		
		.lf_match_info_con_new_two div {
			width: 33.33%;
			height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.28rem;
			color: #333;
		}
		
		.el-carousel__container{
			height: fit-content;
		}
		.tablehead{
		
		   // 指示灯高度
		  :deep(.el-carousel .el-carousel__indicators--horizontal) { 
		    height: 0.5rem;
			
		  }
		// 指示灯未选中的样式
		  :deep(.el-carousel__indicator--horizontal .el-carousel__button){
		   width: 0.14rem;
		    height: 0.14rem;
		    border-radius: 0.14rem;
		    background: #fff;
			border: rgba(153,153,153) 0.01rem solid;
		  }
		// 指示灯选中的样式
		  :deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button){
		    width: 0.14rem;
		   width: 0.14rem;
		    width: 0.14rem;
		    background: #C0153E;
			border: #C0153E 0.01rem solid;
		  }
		}
		.lf_tablebox_tit{
			width: 7.5rem;
		}
	}
</style>
<style>
	.swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;
	
	}
	
	.swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}
	
	.swiper-pagination {
		text-align: left;
		bottom: 1.5rem !important;
		padding-left: 0.5rem;
	}
	
	.swiper-pagination-bullet {
		background-color: #fff;
		border: #DBDBDB 0.01rem solid;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.02rem !important;
	}
	
	@media screen and (max-width: 768px) {
			.swiper-pagination {
					
				bottom: 0.2rem !important;
				padding-left: 3.3rem;
			}
			
	}
</style>