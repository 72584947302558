<template>
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd="sc"></Btn>
	<div class="select-back" v-if="showSelect"></div>
	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{systemlang=='cn'?'赛程':'Schedule'}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{systemlang!='cn'?'赛程':'Schedule'}}</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<div class="all_body">

		<!-- 赛事进行中赛事 -->
		<div v-if="curr.length > 0" class="changebox">
			<div class="changekind">
				<div class="lf_match_info_box">
					<div class="d_title">{{systemlang=='cn'?'进行中赛事':'Current'}} | <span
							class="e_title">{{systemlang!='cn'?'进行中赛事':'Current'}}</span></div>
				</div>
			</div>
		</div>
		<template v-for="(m,index) in curr">
			<div v-if="curr.length > 0" class="title" >
				<div class="titleima">
					<img v-if="systemlang=='cn'" src="../assets/images/u2998.png" />
					<img v-if="systemlang!='cn'" src="../assets/images/u2999.png" />
				</div>
				<div class="lefttitle">
					<!--Match?id=7953&lang=cn-->
					<router-link v-if="WebModel" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'zdcj',id:m.ID,lang:systemlang}}">
						<div class="leftbox1">
							<span
								class="fs">{{langs['matchyf_'+m.month]?langs['matchyf_'+m.month]:+m.month}}</span>{{systemlang=='cn'?'月':'&nbsp;'}}
							{{m.day}}
						</div>
						<div class="leftbox2">
							{{m.ColumnName}}
						</div>
					
						<div class="address">
							<img src="../assets/images/u1522.png" />
							<span class="name">{{m.ColumnPlace}}</span>
						</div>
					</router-link>
					<router-link v-if="!WebModel" target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'lxb',id:m.ID,lang:systemlang}}">
						<div class="leftbox1">
							<span
								class="fs">{{langs['matchyf_'+m.month]?langs['matchyf_'+m.month]:+m.month}}</span>{{systemlang=='cn'?'月':'&nbsp;'}}
							{{m.day}}
						</div>
						<div class="leftbox2">
							{{m.ColumnName}}
						</div>
					
						<div class="address">
							<img src="../assets/images/u1522.png" />
							<span class="name">{{m.ColumnPlace}}</span>
						</div>
					</router-link>
					<div class="leftbox3">
						<router-link target="_blank" :to="{path:'/Match',query:{type:'cj',bFlag:'zdcj',id:m.ID,lang:systemlang}}">
						{{langs.matchyf_matchallprice}}<!--赛事总奖金：-->
						<span class="num">{{m.ColumnPrice}} </span>
						</router-link>
					</div>
					<div v-if="m.clgj" class="address">
						<!--playerInfo?pl_id=1389&lang=cn&bFlag=career-->
						<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:m.clgj.su_pl_id,lang:systemlang}}">
						<div class="lf_body_f_box">
							<img src="../assets/images/u2968.png" />
							<span class="names">{{langs.matchyf_gj_1}}<!--卫冕冠军：-->：</span>
							<span class="namer">{{systemlang=='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}} {{systemlang!='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}}</span>
						</div>
						<div  v-if="m.is_gan=='1'" class="lf_body_f_box_1">
							<span class="names kong">{{langs.matchyf_dgcj}}<!--夺冠成绩：--></span>
							<span class="names lf_names_ red">{{m.clgj.su_to_par}}</span>
						</div>
						</router-link>
					</div>
					<div v-else class="address"></div>
				</div>
				<div class="midbox">
					<div v-if="m.logoappend && m.logoappend.match_logo && m.logoappend.match_logo.length>0" class="boximg"><banner v-if="m.logoappend && m.logoappend.match_logo && m.logoappend.match_logo.length>0" heightNew="0.5rem" :type="2" datatype="1" :dataurl="m.logoappend.match_logo"></banner></div>
				</div>
				<div class="rightbox">
					
					<router-link v-if="m.have_new=='1'" target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'xw',type:'news',id:m.ID,lang:systemlang}}">
					<div class="news">
						<img src="../assets/images/u2994.png" />
						<div class="newstext"><!--赛事新闻-->{{langs.singlematch_ssxw}}</div>
					</div>
					</router-link>

					<div v-if="m.have_score=='1'" class="news left">
						<router-link v-if="m.have_score=='1'" target="_blank" class="lf_a_flex" :to="{path:'/Matchnow',query:{bFlag:'zdcj',type:'cj',id:m.ID,lang:systemlang}}">
						<img src="../assets/images/u2996.png" />
						<div class="newstext">{{langs.RealtimeScore}}</div>
						<div v-if="m.have_zb=='1'" class="shi">LIVE</div>
						</router-link>
					</div>

					<div class="news left " v-if="m.GameNmRegIsOk == '1'">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/SignUp',query:{mt_id:m.ID,lang:systemlang}}">
							<img src="../assets/images/baoming.png" />
							<div class="newstext">{{langs.singlematch_bm}}</div>
						<!-- 报名 -->
						</router-link>
					</div>
				</div>
			</div>
		</template>
	
		<!-- 赛事进行中赛事结束 -->



		<!-- 灰色底色 -->
		<!-- 赛事未来赛事 -->
		<div v-if="futrue.length>0" class="changebox fiveTop">
			<div class="changekind">
				<div class="lf_match_info_box">
					<div class="d_title">{{systemlang=='cn'?'未进行赛事':'Upcoming'}} | <span
							class="e_title">{{systemlang!='cn'?'未进行赛事':'Upcoming'}}</span></div>
				</div>
				
				
			</div>
			<div v-if="futrue.length>0" class="changetime hand">
				<span class="timeword" @click.stop="showSelect = !showSelect">
					{{langs.matchyf_year1}} {{year}} {{langs.matchyf_year}}
				</span>
				<img @click.stop="showSelect = !showSelect" src="../assets/images/u2764.png" />
				
				<div class="select-right into" v-if="showSelect" >
					<div class="select_title">{{langs.matchyf_year3}}</div>
					<div class="select_socl" >
						
						<template v-for="y in years">
						<div @click.stop="changYears(y.value)" class="select_item " :class="year==y.value?'select_item_hover':''"  >{{y.value}}
							<!-- <div v-if="year==y.value" class="close_search">×</div> -->
						</div><!--select_item_hover-->
						
						</template>
					</div>		
				</div>
			</div>
			
		</div>

		<div v-if="futrue.length>0 && systemlang=='cn'" class="smalltext">
			{{year}}年还剩 {{futrue.length}} 场赛事
			
		</div>
		<div v-if="futrue.length>0 && systemlang!='cn'" class="smalltext">
			 {{futrue.length}} event{{futrue.length>1?'s':''}} in the future in {{year}}

		</div>
		
		<template v-for="(m,index) in futrue">
			<div class="lf_body_f">
			<div class="body " >
				<div class="bodybox">
					<router-link target="_blank" :to="{path:'/Match',query:{type:'match',bFlag:'ssgc',id:m.ID,lang:systemlang}}">
						<div class="body1 red" v-if="m.DateContent==''">
							<span
								class="fs">{{langs['matchyf_'+m.month]?langs['matchyf_'+m.month]:+m.month}}</span>{{systemlang=='cn'?'月':'&nbsp;'}}
							{{m.day}}
						</div>
						
						<div v-if="m.DateContent!=''" class="body1 red"  >{{m.DateContent}}</div>

						<div class="body2 black titleleft">
							{{m.ColumnName}}
						</div>
						<div class="addr">
							<div class="location"></div>
							<span class="dizhi black">{{m.ColumnPlace}}</span>
						</div>
					</router-link>
				</div>
				<div class="bodybox1" >
					<router-link v-if="m.ColumnPrice && ( m.ColumnPrice!='')" target="_blank" :to="{path:'/Match',query:{type:'match',bFlag:'ssgc',id:m.ID,lang:systemlang}}">
					<div class="first hu6 left">
						{{langs.matchyf_matchallprice}}
					</div>
					<div class="two black">
						{{m.ColumnPrice}}
					</div>
					</router-link>
				</div>

				<div  v-if="m.clgj" class="bodybox2">
					<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:m.clgj.su_pl_id,lang:systemlang}}">
					<div class="lf_body_f_box">
						<div class="guanjun">
							<div class="jiangbei"></div>
							<div class="dizhi hu6">{{langs.matchyf_gj_1}}：</div>
						</div>
						<div class="ming lf_ming black">
							{{systemlang=='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}}
						</div>
						<div class="ming black">
							{{systemlang!='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}}
						</div>
					</div>
						<div v-if="m.is_gan=='1'" class="chengji black">{{langs.matchyf_dgcj}}
							<span class="red">{{m.clgj.su_to_par}}</span>
						</div>
					</router-link>
				</div>
				<div  v-else class="bodybox2"></div>

				<div  class="bodybox3 lf_fu_mt">
					<div class="end " style="margin-left: 0.14rem;" v-if="m.GameNmRegIsOk == '1'">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/SignUp',query:{mt_id:m.ID,lang:systemlang}}">
						{{langs.singlematch_bm}}<!-- 报名 -->
						</router-link>
					</div>
					<div class="end " v-else-if="m.have_group == '1'">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'fzb',type:'match',id:m.ID,lang:systemlang}}">
						{{langs.singlematch_group}}<!-- 分组 -->
						</router-link>
					</div>
					<div class="end "  style="margin-left: 0.14rem;" v-else-if="m.have_mplayer == '1'">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'csqy',type:'match',id:m.ID,lang:systemlang}}">
						{{langs.singlematch_csmd}}<!-- 参赛名单 -->
						</router-link>
					</div>
					
					
					<div class="end1 " v-if="m.have_new == '1'">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'xw',type:'news',id:m.ID,lang:systemlang}}">
							{{langs.singlematch_news}}<!-- 新闻 -->
						</router-link>
					</div>

					<router-link target="_blank" :to="{path:'/Match',query:{type:'match',bFlag:'ssgc',id:m.ID,lang:systemlang}}">
					<div class="end2 "></div>
					</router-link>
				</div>
				</div>
			</div>
		</template>
		
		<div v-if="futrue.length>0" class="guanggao" v-show="bannershow && bannershow['MATCH_CENTER'] == '1'">
			<banner  v-if="futrue.length>0" type="2" abs="MATCH_CENTER"></banner>
		</div>

		<!-- 赛事结束赛事 -->
		<div v-if="old.length > 0" class="changebox eightTop">
			<div class="changekind">
				<div class="lf_match_info_box">
					<div class="d_title">{{systemlang=='cn'?'已结束赛事':'Completed'}} | <span
							class="e_title">{{systemlang!='cn'?'已结束赛事':'Completed'}}</span></div>
				</div>

				

			</div>
			<div v-if="futrue.length <= 0" class="changetime hand">
				<span class="timeword" @click.stop="showSelect = !showSelect">
					{{langs.matchyf_year1}} {{year}} {{langs.matchyf_year}}
				</span>
				<img @click.stop="showSelect = !showSelect" src="../assets/images/u2764.png" />
				
				<div class="select-right into" v-if="showSelect" >
					<div class="select_title">{{langs.matchyf_year3}}</div>
					<div class="select_socl" >
						
						<template v-for="y in years">
						<div @click.stop="changYears(y.value)" class="select_item " :class="year==y.value?'select_item_hover':''"  >{{y.value}}
							<!-- <div v-if="year==y.value" class="close_search">×</div> -->
						</div><!--select_item_hover-->
						
						</template>
					</div>		
				</div>
			</div>
		</div>

		<div v-if="old.length > 0 && systemlang=='cn'" class="smalltext">
			{{year}}年完成 {{old.length}} 场赛事
		</div>
		<div v-if="old.length > 0 && systemlang!='cn'" class="smalltext">
			{{old.length}} event{{old.length>1?'s':''}} completed in {{year}}
		</div>

		<!-- <empty v-if="old.length <= 0"></empty> -->
		<template v-for="(m,index) in old">
			<div class="lf_body_f">
			<div class="body " >
				<div class="bodybox">
					<router-link target="_blank" :to="{path:'/Match',query:{bFlag:'',type:'cj',id:m.ID,lang:systemlang}}">
						<div class="body1 red">
							<span
								class="fs">{{langs['matchyf_'+m.month]?langs['matchyf_'+m.month]:+m.month}}</span>{{systemlang=='cn'?'月':'&nbsp;'}}
							{{m.day}}
						</div>
						<div class="body2 black titleleft">
							{{m.ColumnName}}
						</div>
						<div class="addr">
							<div class="location"></div>
							<span class="dizhi black">{{m.ColumnPlace}}</span>
						</div>
					</router-link>
				</div>
				<div class="bodybox1" >
					<router-link v-if="m.ColumnPrice && ( m.ColumnPrice!='')" target="_blank" :to="{path:'/Match',query:{bFlag:'',type:'cj',id:m.ID,lang:systemlang}}">
						<div class="first hu6 left">
							{{langs.matchyf_matchallprice}}
						</div>
						<div class="two black">
							{{m.ColumnPrice}}
						</div>
					</router-link>
				</div>

				<div  v-if="m.clgj" class="bodybox2">
					<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:m.clgj.su_pl_id,lang:systemlang}}">
						<div class="lf_body_f_box">
							<div class="guanjun">
								<div class="jiangbei"></div>
								<div class="dizhi hu6">{{langs.matchyf_gj1_1}} {{ m.clgj.y }}：</div>
							</div>
							<div class="ming lf_ming black">
								{{systemlang=='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}}
							</div>
							<div class="ming black">
								{{systemlang!='cn'?m.clgj.pl_cn_name:(m.clgj.firstname+' '+m.clgj.lastname)}}
							</div>
						</div>
					<div  v-if="m.is_gan=='1'" class="chengji black">{{langs.matchyf_dgcj}}
						<span  class="red">{{m.clgj.su_to_par}}</span>
					</div>
					</router-link>
				</div>
				<div  v-else class="bodybox2"></div>

				<div class="bodybox3 lf_end_mt">
					<div class="end ">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'',type:'cj',id:m.ID,lang:systemlang}}">
						{{langs.stats_chengji}}<!-- 成绩 -->
						</router-link>
					</div>
					<div class="end1 ">
						<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'jstj',type:'match',id:m.ID,lang:systemlang}}">
							{{langs.Stats}}<!-- 技术统计 -->
						</router-link>
					</div>
					<router-link target="_blank" class="lf_a_flex" :to="{path:'/Match',query:{bFlag:'',type:'cj',id:m.ID,lang:systemlang}}">
					<div class="end2 ">

					</div>
					</router-link>
				</div>
				</div>
			</div>
		</template>

	</div>

	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../components/nav.vue";
	import Btn from "../components/Btn.vue";
	import Footer from "../components/Footer.vue";
	import Footer1 from "../components/Footer1.vue";
	import axios from '../axios/request';
	import banner from "../components/banner.vue";
	import empty from "../components/empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'

	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}



	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				years: years,
				year: enddata,
				old: [], //过去赛事
				futrue: [], //未来赛事
				curr: [], //当前赛事
				bannershow:[],
				showSelect:false,
				WebModel:true,


			}
		},
		props: {

		},
		watch: {
			showSelect:function(newVal,oldVal){
				var width = document.body.clientWidth;
				if(width<=750 && newVal == true){
				document.body.style.overflow = 'hidden'
				}else{
				document.body.style.overflow = 'unset'
				}
				
			},
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			this.getmaxyear();
			this.getCurrData();
			this.getData();
			

			document.body.addEventListener('click', (e) => {
			
				this.showSelect = false;
			});

			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth >= 770) {
				this.WebModel = true;
				
			} else {
				this.WebModel = false;
			}


		},
		setup() {

		},
		mounted(){
			
			window.addEventListener('resize', () => {
				var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 770) {
					this.WebModel = true;
					
				} else {
					
					this.WebModel = false;
				}
			})
			
		},
		methods: {
			changYears(e){
			this.year = e;
			this.getData(this.year);
			this.getCurrData();
			this.showSelect = false;
		},
			setbannershow(abs, isshow) {
				// console.log(abs,isshow);
				this.bannershow[abs] = isshow;
			},
			getmaxyear() {
				axios('matchs//match/maxyear.json', {})
					.then((res) => {
						var enddata = res.data.data.year;
						var years = [];
						for (var i = enddata; i >= 2004; i--) {
							years.push({
								value: i,
								label: i
							});
						}
						this.years = years;
					});
			},
			getDataold(year) {
				
				this.old = [];
				let param = {};
				if (year) {
					param.year = year;
				} else {
					param.year = this.year;
				}
				param.limit = 'all';
				axios('matchs/match/list.json', param)
					.then((res) => {
						let data = res.data.data;
						// this.list = data;

						
						for (let i in data) {
							if (data[i].zt == -1) {
								this.old.push(data[i]);
							} 
						}
						
					});
			},
			getData(year) {
				this.futrue = [];
				this.old = [];
				this.curr = [];
				let param = {};
				if (year) {
					param.year = year;
				} else {
					param.year = this.year;
				}
				param.limit = 'all';
				axios('matchs/match/list.json', param)
					.then((res) => {
						let data = res.data.data;
						if(data.length<=0)
						{
							this.year = this.year-1;
							this.getData(this.year);
							return false;
						}
						// this.list = data;

						let futrue = [];
						for (let i in data) {
							if (data[i].zt == -1) {
								this.old.push(data[i]);
							} else if (data[i].zt == 1) {
								futrue.push(data[i]);
							}else if (data[i].zt == '0') {
								this.curr.push(data[i]);
							}
						}
						futrue.reverse();
						for (let i in futrue) {
							this.futrue.push(futrue[i]);
						}
					});
			},
			getCurrData(year) {
				return false;
				this.curr = [];
				let param = {};
				param.year = this.year;
				param.limit = 'all';
				axios('matchs/match/list.json', param)
					.then((res) => {
						let data = res.data.data;
						for (let i in data) {
							if (data[i].zt == '0') {
								this.curr.push(data[i]);
							}
						}
					});
			},
		}

	}
</script>


<style scoped="scoped">
	.guanggao {
		width: 18.5rem;
		height: 1.85rem;
		margin: 0 auto;
		margin-top: 0.4rem;
	}
	

	.fiveTop {
		margin-top: 0.5rem;
	}

	.eightTop {
		margin-top: 0.8rem;
	}

	.title {
		width: 18.5rem;
		background-color: #FFF1F2;
		border-bottom: 0.04rem solid #C0153E;
		position: relative;
		display: flex;
		align-items: center;
	}

	.body {
		width: 18.5rem;
		
		background-color: #FAFAFA;
		overflow: hidden;
	}



	.bodybox {
		width: 7.3rem;
		
		float: left;
		overflow: hidden;

	}

	.bodybox1 {
		width: 3rem;
		
		float: left;
		overflow: hidden;
	}

	.bodybox2 {
		width: 3.3rem;
		
		float: left;

	}

	.bodybox3 {
		width: 5rem;
		margin-right: 0.58rem;
		float: left;
		overflow: hidden;

	}

	.end {
		width: 1.2rem;
		height: 0.5rem;
		border-radius: 0.02rem;

		line-height: 0.5rem;
		text-align: center;
		font-size: 0.24rem;
		color: #333333;
		border: 0.02rem solid #333333;
		margin-left: 1.4rem;
		float: left;
	}

	.end1 {
		width: 1.2rem;
		height: 0.5rem;
		border-radius: 0.02rem;

		line-height: 0.5rem;
		text-align: center;
		font-size: 0.24rem;
		color: #333333;
		border: 0.02rem solid #333333;
		margin-left: 0.14rem;
		float: left;
	}

	.end2 {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.02rem;
		border: 0.02rem solid #333333;
		text-align: center;
		line-height: 0.5rem;
		margin-left: 0.14rem;
		float: left;
		background: url('../assets/images/u2757.png') no-repeat;
		background-size: 0.3rem;
		background-position: center;
	}

	.lf_end_mt .end2 {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.02rem;
		border: 0.02rem solid #333333;
		text-align: center;
		line-height: 0.5rem;
		margin-left: 0.14rem;
		float: left;
		background: url('../assets/images/u2786.png') no-repeat;
		background-size: 0.3rem;
		background-position: center;
	}
	.first {
		width: 2.5rem;
		height: 0.3rem;
		line-height: 0.3rem;
		text-align: left;
		color: #ffffff;
		font-size: 0.24rem;
		margin-left: 0.5rem;
	}

	.two {
		width: 2.5rem;
		height: 0.3rem;
		text-align: left;
		line-height: 0.3rem;
		color: #ffffff;
		font-size: 0.26rem;
		font-weight: bold;
		margin-left: 0.5rem;

	}

	.titleima img {
		width: 1rem;
		height: 1rem;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 11;
		-moz-transform: rotate(-270deg);
		-webkit-transform: rotate(-270deg);
	}

	.lefttitle {
		width: 7rem;
		float: left;
		overflow: hidden;
		margin-left: 0.5rem;

	}

	.midbox {
		width: 6rem;
		height: 3.2rem;
		float: left;
		display: flex;
		align-items: center;
		
	}

	.rightbox {
		width: 5rem;
		float: left;
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: flex-end;
		padding-right: 0.9rem;
	}

	.midbox .boximg {
		width: 3.75rem;
		height: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.leftbox1 {
		width: 7rem;
		height: 0.8rem;
		font-size: 0.26rem;
		color: #C0153E;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
	.all_body{
		padding-bottom: 0.4rem;
	}
	.all_body .body{
		border-bottom: 1px solid #dbdbdb;
		width: 18.5rem;
		display: flex;
		align-items: center;
		padding: 0.2rem 0;
	}
	.all_body:nth-last-of-type(1) .body{
		border-bottom:unset;
	}
	.lf_body_f{
		background-color: #FAFAFA;
		width: 18.5rem;
		margin-left: 0;
	}
	.body1 {
		width: 5rem;
		height: 0.8rem;
		font-size: 0.26rem;
		color: #ffffff;
		font-weight: bold;
		margin-left: 0.5rem;
		display: flex;
		align-items: center;
	}

	.leftbox2 {
		width: 6rem;
		color: #333333;
		font-weight: bold;
		font-size: 0.32rem;
		text-decoration-line: underline;
		margin-left: 0.66rem;
	}

	.body2 {
		width: 5rem;
		color: #ffffff;
		font-weight: bold;
		font-size: 0.3rem;
		text-decoration-line: underline;
		margin-left: 1.1rem;
	}


	.leftbox3 {
		width: 6rem;
		height: 0.5rem;
		
		color: #666666;
		font-size: 0.24rem;
		margin-left: 0.6rem;
		margin-top: 0.03rem;
	}

	.names {
		height: 0.5rem;
		color: #666666;
		font-size: 0.24rem;
	}
	.lf_names_{
		font-size: 0.26rem;
		font-weight: bold;
	}
	.kong {
		margin-left: 0.2rem;
	}

	.red {
		color: #C0153E;
	}

	.namer {
		height: 0.5rem;
		color: #333333;
		font-weight: bold;
		font-size: 0.28rem;
		text-decoration-line: underline;

	}

	.ming {
		color: #ffffff;
		font-weight: bold;
		font-size: 0.26rem;
		text-decoration-line: underline;
	}

	.num {
		font-size: 0.28rem;
		color: #333333;
		font-weight: bold;
	}

	.fs {
		font-size: 0.36rem;
	}

	.address {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 0.3rem;
		margin-top: 0.03rem;
	}

	.guanjun {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 0.2rem;
		position: relative;
		left: -0.3rem;
	}

	.jiangbei {
		width: 0.26rem;
		height: 0.26rem;
		background: url('../assets/images/u2968.png') no-repeat;
		background-size: 0.26rem;
		margin-right: 0.04rem;
	}

	.addr {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 0.03rem;
		margin-left: 0.8rem;
		margin-bottom: 0.1rem;
	}

	.location {
		flex-shrink: 0;
		width: 0.26rem;
		height: 0.26rem;
		background: url('../assets/images/u1522.png') no-repeat;
		background-size: 0.26rem;
		margin-right: 0.04rem;
	}



	.name {
		font-size: 0.24rem;
		color: #333333;
	}

	.dizhi {
		font-size: 0.24rem;
		color: #ffffff;
	}

	.chengji {
		font-size: 0.24rem;
		color: #ffffff;
		margin-top: 0.04rem;
	}
	.chengji .red{
		font-weight: bold;
		font-size: 0.26rem;
	}
	.address img {
		width: 0.26rem;
		height: 0.26rem;
		margin-right: 0.04rem;

	}

	.news {
		width: 1.4rem;
		height: 1.4rem;
		margin-left: 0.8rem;
		border: 0.04rem solid #C0153E!important;
		font-size: 0.28rem;
		border-radius: 0.02rem;
		float: left;
		position: relative;
	}

	.shi {
		position: absolute;
		z-index: 11;
		right: 0;
		top: 0;
		width: 0.5rem;
		height: 0.35rem;
		font-size: 0.18rem;
		text-align: center;
		color: #C0153E;
	}

	.left {
		margin-left: 0.4rem;
	}

	.news img {
		width: 0.7rem;
		height: 0.7rem;
		margin-top: 0.2rem;
		margin-left: 0.3rem;
	}

	.newstext {
		width: 1.36rem;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #C0153E;
		font-size: 0.28rem;
		text-align: center !important;

	}

	.black {
		color: #333333 !important;
	}

	.hu6 {
		color: #666666 !important;
	}

	.body:hover {
		background-color: #C0153E;

	}

	.body:hover .body1,
	.body:hover .body2,
	.body:hover .dizhi,
	.body:hover .first,
	.body:hover .two,
	.body:hover .ming,
	.body:hover .chengji,
	.body:hover .red {
		color: #fff !important;
	}
	
	.body:hover .end,
	.body:hover .end1 {
		color: #fff;
		border: #ffffff 0.03rem solid;
	}
.select-right{
	top: 0.6rem;
}
	.body:hover .end2 {

		border: #ffffff 0.03rem solid;
		background: url('../assets/images/u2737.png') no-repeat;
		background-size: 0.3rem;
		background-position: center;
	}

	.body:hover .jiangbei {

		background: url('../assets/images/u2973.png') no-repeat;
		background-size: 0.26rem;
	}

	.body:hover .location {
		background: url('../assets/images/u2981.png') no-repeat;
		background-size: 0.26rem;
	}
	.hui{
		color: #999999;
		border-color: #999999;
	}
	.hui2{
		background: url('../assets/images/u2786.png') no-repeat;
		background-size: 0.3rem;
		background-position: center;
		border-color: #999999;
	}
	
	
	.changetime {
		width: 2.6rem;
		text-align: center;
		background-color: #C0153E;
		border-radius: 0.1rem;
		height: 0.5rem;
	
		line-height: 0.5rem;
	
		font-size: 0.28rem;
		color: #fff;
		font-weight: bold;
		float: right;
		margin-right: 0.63rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.changetime img {
		width: 0.3rem;
		height: 0.3rem;
		float: left;
		margin-top: 0;
		margin-left: 0.1rem;
	}
	
	.timeword {
		float: left;
		font-size: 0.3rem;
	}
	
	.smalltext{
		font-size: 0.2rem ;
		color: #666666;
		margin-top: -0.1rem;
		margin-left: 0.4rem;
		margin-bottom: 0.2rem;
	}
	.lf_fu_mt{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.lf_fu_mt .end,
	.lf_fu_mt .end1,
	.lf_fu_mt .end2{
		color: #333333;
		border: 0.02rem solid #333333;
	}
	.lf_end_mt .end,
	.lf_end_mt .end1,
	.lf_end_mt .end2{
		color: #999;
		border: 0.02rem solid #999;
	}
	.lf_end_mt,.lf_fu_mt{
		display: flex;
		align-items: center;
	}
	.lf_body_f_box_1{
		margin-top: 0.06rem;
		margin-bottom: 0.4rem;
	}
	.lf_body_f_box_1 .names{
		margin-left: 0.3rem;
	}
	@media screen and (max-width: 768px) {

		.smalltext{
			margin-bottom: unset;
			margin-left: 0.5rem;
		}
		.d_title{
			margin-left: 0;
		}
		.guanggao {
			width: 7.5rem;
			height: 0.75rem;
			margin-left: 0rem;
			margin-top: 0.4rem;
		}

		.title {
			width: 7.1rem;
			height: fit-content;
			background-color: #FFF1F2;
			border-bottom: 0.04rem solid #C0153E;
			margin-left: 0.2rem;
			margin-top: 0.4rem;
			position: relative;
			display: flex;
			flex-direction: column;
			padding-bottom: 0.3rem;
		}

		.lefttitle {
			width: 6.9rem;
			height: auto;
			float: left;
			overflow: hidden;
			margin-left: 0.1rem;

		}

		.midbox {
			width: 0rem;
			height: 0rem;
			float: left;
		}

		.midbox img {
			display: none;
		}

		.rightbox {
			width: 7rem;
			float: left;
			clear: both;
			height: fit-content;

		}

		.news {
			width: 1.4rem;
			height: 1.4rem;
			margin-left: 2rem;
			border: 0.04rem solid #C0153E!important;
			font-size: 0.28rem;
			margin-top: 0.1rem;
			border-radius: 0.02rem;
			float: left;
			position: relative;
		}

		.left {
			margin-left: 0.4rem;
		}

		.all_body .body {
			width: 100%;
			flex-direction: column;
			overflow: hidden;
		}

		.bodybox {
			width: 6.9rem;
			float: left;
			overflow: hidden;
			margin-left: 0.1rem;
			display: flex;
			align-items: center;
		}

		.body1 {
			width: 6rem;
			height: 0.8rem;
			line-height: 0.8rem;
			font-size: 0.26rem;
			color: #ffffff;
			font-weight: bold;
			margin-left: .15rem;
		}

		.body2 {
			width: 6rem;
			height: fit-content;
			line-height: normal;
			color: #ffffff;
			font-weight: bold;
			font-size: 0.3rem;
			text-decoration-line: underline;
			margin-left: 0.45rem;
		}
		.titleleft{
			margin-left: 0.45rem;
			margin-bottom: 0.1rem;
		}
		.addr {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 0.19rem;
			margin-bottom: 0.06rem;
		}

		.bodybox1 {
			width: 6.9rem;
			height: 0.4rem;
			float: left;
			display: flex;
			align-items: center;
			overflow: hidden;
		}

		.first {
			width: 1.5rem;
			height: 0.3rem;
			line-height: 0.3rem;
			margin-top: 0rem;
			color: #ffffff;
			font-size: 0.24rem;
			margin-left: 0.7rem;
			float: left;
		}

		.two {
			height: 0.3rem;
			line-height: 0.3rem;
			color: #ffffff;
			font-size: 0.26rem;
			font-weight: bold;
			margin-top: 0rem;
			float: left;
			margin-left: 0;
		}

		.left {
			margin-left: 0.52rem;
		}

		.bodybox2 {
			width: 6.5rem;
			float: left;
			overflow: hidden;
			display: flex;
			align-items: center;
			line-height: normal;
		}

		.guanjun {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-top: 0rem;
			float: left;
			margin-left: .04rem;
			position: unset;
			padding-left: 0;
		}

		.jiangbei {
			margin-right: 0.04rem;
		}


		.ming {
			color: #ffffff;
			font-weight: bold;
			font-size: 0.26rem;
			text-decoration-line: underline;
			margin-left: 0.35rem;
			float: left;
			height: 0.4rem;
			line-height: 0.4rem;

		}
		.lf_ming{
			margin-left: 0;
		}
		.chengji {
			font-size: 0.24rem;
			color: #ffffff;
			margin-left: 0.35rem;
			margin-top: 0.04rem;
			float: left;
		}

		.bodybox3 {
			width: 6.8rem;
			height: 1rem;
			display: flex;
			align-items: center;
			justify-content: flex-end;

		}

		.end {
			width: 1.2rem;
			height: 0.5rem;
			border-radius: 0.02rem;
			border: 0.03rem solid #ffffff;
			line-height: 0.5rem;
			text-align: center;
			font-size: 0.24rem;
			color: #ffffff;
			margin-left: 3.4rem;
			float: left;
		}

		.end1 {
			width: 1.2rem;
			height: 0.5rem;
			border-radius: 0.02rem;
			border: 0.03rem solid #ffffff;
			line-height: 0.5rem;
			text-align: center;
			font-size: 0.24rem;
			color: #ffffff;
			margin-left: 0.3rem;
			float: left;
		}

		.hei {
			color: #333333 !important;
			border: 0.03rem solid #333333 !important;
		}

		.end2 {
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 0.02rem;
			border: 0.03rem solid #ffffff;
			text-align: center;
			line-height: 0.5rem;
			margin-left: 0.3rem!important;
			float: left;
		}

		.end2 img {
			width: 0.3rem;
			height: 0.3rem;
			margin-left: 0.1rem;
			margin-top: 0.1rem;
		}

		.black {
			color: #333333 !important;
		}

		.hu6 {
			color: #666666 !important;
		}

		.red {
			color: #C0153E;
		}

		.headred .heady{
		font-size: 0.24rem;
		color: #fff;
	}


	.heady {
		width: 3.1rem;
		float: left;
		font-size: 0.28rem;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000000;
	}

	.heady img {
		width: 0.36rem;
		height: 0.36rem;
		margin-left: 0.1rem;
	}
	.midbox .boximg{
		display: none;
	}
	.lf_body_f_box{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.lf_body_f_box_1{
		display: flex;
		align-items: center;
		margin-bottom: 0;
	}
	.lf_body_f_box .names,.lf_body_f_box .namer{
		height: auto;
	}
	.lf_body_f_box_1 .kong{
		margin-left: 0.3rem;
	}
	.lf_body_f{
		background-color: #FAFAFA;
		width: 7.1rem;
		margin-left: 0.2rem;
	}
	.changetime{
		margin-right: 0;
	}

	}
</style>