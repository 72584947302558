<template>
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner  :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd="ph"></Btn>
<div class="select-back" v-if="typeshow || yearshow" @click="typeshow=false;yearshow=false;unlock();"></div>

	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs["Players & Stats"]}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs["Players & Stats_en"]}}</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<div class="head">
		<div class="head_top">
			<div class="searchimg">
				<img src="../../assets/images/u6324.png" />
			</div>
			<div class="searchbox" style="position: relative;">
				<input v-model="searchkey" placeholder="" type="text"  @keyup.enter="search(searchkey,'input')"/>
				<img @click="searchM()" v-if="searchkey!=''" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" src="../../assets/images/u21104.png" alt="" />
				<div class="searchbutton hand" @click="search(searchkey,'input')">{{langs.psearch_search}}</div>
			</div>
		</div>
		<div class="searchword">{{langs.psearch_contentdesc}}</div>
	</div>
	<div class="all_body">

		<div class="changebox">
			<div class="changekind hand">
				<div class="lf_match_info_box">

					<div @click.stop="changetypeshow" >
						<div class="d_title" v-if="ranktypelist[ranktype]!=undefined">{{ranktypelist[ranktype].titleleft}} | <span>{{ranktypelist[ranktype].titleright}}</span></div>
						<img src="../../assets/images/u182.png" class="d_img" />
					</div>
					<div class="select-left into" v-if="typeshow">
						<div class="select_title">{{ranktypelist[ranktype].titleleft}} | {{ranktypelist[ranktype].titleright}}</div>
						<div class="select_socl">
							<div class="select_item" :class="ranktype==item.value?'select_item_hover':''" @click="changeranktype(item.value)" v-for="item in ranktypearr">{{item.label}}</div>
						</div>
					</div>


				</div>
			</div>
			<div class="changeday" v-if="ranktype=='laolishi'&&list[0]">
				<span class="dayword">
					{{langs.tj_date}}{{ list[0].mt_date }}
				</span>
			</div>
			<div class="changeday" v-else-if="ranktype=='jifenrank'&&list[0]">
				<span class="dayword">
					{{langs.tj_date}}{{ list[0].match.mt_end_date }}
				</span>
			</div>
			<div class="changeTimeAll" v-else>
				<div class="changetime_new hand" @click.stop="changeyearshow">
					<span class="timeword_new">
						{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}
					</span>
					<img src="../../assets/images/u2764.png" />
				</div>
				<div class="changetime changetime_s" @click.stop="changeyearshow">
					<span class="timeword">
						{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}
					</span>
					<div class="changetimeimg">
						<img src="../../assets/images/u182.png" />
					</div>
					
				</div>
				<div class="select-right into" style="top: 0.6rem;" v-if="yearshow">
					<div class="select_title">{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}</div>
					<div class="select_socl">
						<div class="select_item" :class="year=='all'?'select_item_hover':''" @click="changeyear('all')">{{langs.quanbu}}</div>
						<div class="select_item" :class="year==item.value?'select_item_hover':''" @click="changeyear(item.value)" v-for="item in options">{{item.label}}</div>
					</div>
				</div>
			</div>
		</div>
		

		<div class="table" v-loading="loading">
			<div class="tableitem ">
				<span class="headwordp">{{langs.paiming}}</span>
				<span class="headwordq">{{langs.text_player}}</span>
				<span class="headwords" v-if="ranktype=='prizerank'">{{langs.CLPGARANKINGS_jiangjin}}</span>
				<span class="headwords" v-if="ranktype=='jifenrank'">{{langs.text_price}}</span>
				<span class="headwords" v-if="ranktype=='laolishi'">{{langs.pingjunjifen}}</span>
				<span class="headwords" v-if="ranktype=='prizerank'">{{langs.Rounds}}/{{langs.Rounds1}}</span>
				<span class="headwords" v-if="ranktype=='jifenrank'">{{langs.Rounds}}/{{langs.Rounds1}}</span>
				<span class="headwords" v-if="ranktype=='laolishi'">{{langs.cansaichangci}}</span>
			</div>
			<template v-for="(item,index) in list" :key="index">
				<div class="banner lf_LAOLISHI_CENTER" v-if="index%20==0&&index!=0&&abs=='LAOLISHI_CENTER'" v-show="bannershow && bannershow['LAOLISHI_CENTER'] == '1'">
					<banner v-if="abs!=''"  :type="2" abs="LAOLISHI_CENTER"></banner>
				</div>
				<div class="banner lf_CLPGA_CENTER" v-if="index%20==0&&index!=0&&abs=='CLPGA_CENTER'" v-show="bannershow && bannershow['CLPGA_CENTER'] == '1'">
					<banner v-if="abs!=''" :type="2" abs="CLPGA_CENTER"></banner>
				</div>
				<div class="banner lf_CLPGA_CENTER" v-if="index%20==0&&index!=0&&abs=='JIFEN_CENTER'" v-show="bannershow && bannershow['JIFEN_CENTER'] == '1'">
					<banner v-if="abs!=''" :type="2" abs="JIFEN_CENTER"></banner>
				</div>
				<div class="tableitemBody" :class="index%2==0 ? 'backWhite':''" :id="item.id">
					<div class="bodywordp">{{item.rank}}</div>
					<div class="bodywordq">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:item.id,lang:systemlang}}">
							<img :src="item.pl_country_img" alt="" class="country" />
							<div class="center">
								<span class="bodyname hoverUnderLine">{{item.cn_name}}</span>
								<span class="bodynamee hoverUnderLine">{{item.en_name}}{{item.type}}</span>
								
							</div>
							<!-- <template v-for="(zhanzhupic,index1) in item.zhanzhu" :key="index1">
								<div class="headlogo">
									<img :src="'/'+zhanzhupic" alt="" />
								</div>
							</template> -->
							<div class="headlogo">
								<banner :type="2" datatype="1" :dataurl="item.zhanzhu"></banner>
							</div>
						</router-link>
					</div>
					<div class="headwords">
						<span v-if="ranktype=='prizerank'" style="color: #333;">{{item.score}}</span>
						<span v-else style="color: #333;">{{item.score}}</span>
					</div>
					<span class="headwords"  style="color: #333 !important;">
						<span v-if="ranktype=='prizerank'">{{item.match_join_count}}/</span>
						<span v-else-if="ranktype=='jifenrank'">{{item.match_join_count}}/</span>
						<span>{{item.events}}</span>
					</span>
				</div>
				<!-- <div class="tableitemBody" v-for="item in 10">
					<div class="bodywordp">1</div>
					<div class="bodywordq">
						<img src="../../assets/images/u33.png" alt="" class="country" />
						<div class="center">
							<div class="bodyname">刘文博</div>
							<div class="bodynamee">Wenbo LIU</div>
						</div>

						<div class="headlogo">
							<img src="../../assets/images/u33.png" alt="" />
						</div>
					</div>
					<span class="headwords">

						12
					</span>
					<div class="headwords">
						<span style="color: #000;">24</span>
					</div>
				</div> -->
			</template>
		</div>

	</div>

	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	
	import banner from "../../components/banner.vue";
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}



	//自动轮播的配置
	export default {
		data() {
			return {
				input: '',
				langs: '',
				systemlang: 'cn',
				input: '',
				value: '',
				years: years,
				year: '',
				matchs: [],
				mt_id: '',
				pl_name: '',
				banner: {},
				wonderful: {},
				xhs: {},
				match: {},
				//xhs:巡回赛新闻 match:赛站新闻
				options:[],
				year:"",
				ranktype:"",
				curranktype:"",
				list:[],
				ranktypearr:[],
				ranktypelist:{},
				searchkey:"",
				w:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
				typeshow:false,
				yearshow:false,
				bannershow:{},
				abs:"",
				loading: false,
				pl_id:""
			}
		},
		props: {

		},
		watch: {
			
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			banner
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.pl_id = this.$route.query.pl_id;
			var year = (new Date()).getFullYear();
			var maxyear = (new Date()).getFullYear();
			var yearoptions = [];
			for(var i=year;i>=2010;i--){
				yearoptions.push({"value":i,"label":i})
			}
			this.options = yearoptions;
			this.year = year;
			if(this.$route.query.year){
				this.year = this.$route.query.year;
			}
			var ranktype = this.$route.query.ranktype;
			this.ranktype = ranktype;
			this.curranktype = ranktype;
			this.getData(ranktype,this.year);
			if(ranktype == "laolishi"){
				this.abs = "LAOLISHI_CENTER";
			}else if(ranktype == "prizerank"){
				this.abs = "CLPGA_CENTER";
			}else if(ranktype == "jifenrank"){
				this.abs = "JIFEN_CENTER";
			}
			this.ranktypearr.push({value:"laolishi",label:this.langs["ROLEX RANKINGS"]})
			this.ranktypelist["laolishi"] = {titleleft:this.langs["ROLEX RANKINGS"],titleright:this.langs["ROLEX RANKINGS_en"]}
			this.ranktypearr.push({value:"jifenrank",label:this.langs["Score list"]})
			this.ranktypelist["jifenrank"] = {titleleft:this.langs["Score list"],titleright:this.langs["Score list_en"]}
			this.ranktypearr.push({value:"prizerank",label:this.langs["Order of Merit"]})
			this.ranktypelist["prizerank"] = {titleleft:this.langs["Order of Merit"],titleright:this.langs["Order of Merit_en"]}
			document.body.addEventListener('click', (e) => {
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
			});
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				// console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: true,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			search: function(searchkey,type) {
				if(searchkey==""){
					alert(this.systemlang=='cn'?'请输入内容':"Please enter content");
					return;
				}
				// PlayerSearch?lang=cn&leter=B&searchkey=B&page=1
				if(type == "leter"){
					var leter = searchkey;
				}else{
					var leter = ""
				}
				var params = {
					lang:getSystemLang(),
					leter:leter,
					searchkey:searchkey
				}
				let routeUrl = this.$router.resolve({
					path: "/PlayerSearch",
					query: params
				});
				// console.log(routeUrl.href);
				window.open(routeUrl.href, '_self');
			},
			changeyear(year){
				this.year = year;
				var ranktype = this.ranktype;
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
				this.getData(ranktype,year);
			},
			changeranktype(ranktype){
				this.ranktype = ranktype;
				var year = this.year;
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
				if(ranktype == "laolishi"){
					this.abs = "LAOLISHI_CENTER";
				}else if(ranktype == "prizerank"){
					this.abs = "CLPGA_CENTER";
				}else if(ranktype == "jifenrank"){
					this.abs = "JIFEN_CENTER";
				}
				this.getData(ranktype,year);
			},
			getData(ranktype,year){
				this.loading = true;
				this.list = [];
				if(ranktype == "sg"){
					axios('sgrank/getyeardata.json',{year:year})
					.then((res)=>{
						var sglist = res.data.list.sum;
						if(sglist.length==0){
							this.sglist = sglist;
						}else{
							for(var key in sglist){
								if(getSystemLang()=="cn"){
									sglist[key]["cn_name"] = sglist[key]["pl_cn_name"];
									sglist[key]["en_name"] = sglist[key]["pl_en_name"];
								}else{
									sglist[key]["cn_name"] = sglist[key]["pl_en_name"];
									sglist[key]["en_name"] = sglist[key]["pl_cn_name"];
								}
								sglist[key]["rank"] = sglist[key]["sum_rank"];
								sglist[key]["thisyear_match_count"] = sglist[key]["sum_rank"];
								sglist[key]["score"] = sglist[key]["sum_sg"];
							}
							this.list = sglist;
						}
						this.loading = false;
						this.$nextTick(()=>{
							if(this.pl_id){
								if(location.href.indexOf("#") == -1){
									location.href += "#"+this.pl_id;
								}
							}
						})
					});
				}else if(ranktype == "laolishi"){
					axios('sgrank/getlaolishirank.json',{year:year})
					.then((res)=>{
						var laolishirank = res.data;
						if(laolishirank.length==0){
							this.laolishirank = laolishirank;
						}else{
							for(var key in laolishirank){
								if(getSystemLang()=="cn"){
									laolishirank[key]["cn_name"] = laolishirank[key]["cn_name"];
									laolishirank[key]["en_name"] = laolishirank[key]["en_name"];
								}else{
									var temp = laolishirank[key]["cn_name"];
									laolishirank[key]["cn_name"] = laolishirank[key]["en_name"];
									laolishirank[key]["en_name"] = temp;
								}
							}
							console.log(laolishirank);
							this.list = laolishirank;
						}
						this.loading = false;
						this.$nextTick(()=>{
							if(this.pl_id){
								if(location.href.indexOf("#") == -1){
									location.href += "#"+this.pl_id;
								}
							}
						})
					});
				}else if(ranktype == "prizerank"){
					axios('sgrank/getprizerank.json',{year:year})
					.then((res)=>{
						var prizelist = res.data;
						if(res.data.data && res.data.data.length < 1)
						{
							this.loading = false;
							return false;
						}
						if(prizelist.length==0){
							this.prizelist = prizelist;
						}else{
							for(var key in prizelist){
								prizelist[key]["id"] = prizelist[key]["pl_id"];
								if(getSystemLang()=="cn"){
									prizelist[key]["cn_name"] = prizelist[key]["cn_name"];
									prizelist[key]["en_name"] = prizelist[key]["en_name"];
								}else{
									var temp = prizelist[key]["cn_name"];
									prizelist[key]["cn_name"] = prizelist[key]["en_name"];
									prizelist[key]["en_name"] = temp;
								}
								prizelist[key]["events"] = prizelist[key]["count_match"];
							}
							console.log(prizelist);
							this.list = prizelist;
						}
						this.loading = false;
						this.$nextTick(()=>{
							if(this.pl_id){
								if(location.href.indexOf("#") == -1){
									location.href += "#"+this.pl_id;
								}
							}
						})
					});
				}else if(ranktype == "jifenrank"){
					if(year<2025){
						year = 2025;
					}
					axios('sgrank/getjifenrank.json',{year:year})
					.then((res)=>{
						var jifenrank = res.data;
						if(jifenrank.length==0){
							this.jifenrank = jifenrank;
						}else{
							for(var key in jifenrank){
								if(getSystemLang()=="cn"){
									jifenrank[key]["cn_name"] = jifenrank[key]["cn_name"];
									jifenrank[key]["en_name"] = jifenrank[key]["en_name"];
								}else{
									var temp = jifenrank[key]["cn_name"];
									jifenrank[key]["cn_name"] = jifenrank[key]["en_name"];
									jifenrank[key]["en_name"] = temp;
								}
								jifenrank[key]["id"] = jifenrank[key]["pl_id"]-200000;
							}
							console.log(jifenrank);
							this.list = jifenrank;
						}
						this.loading = false;
						this.$nextTick(()=>{
							if(this.pl_id){
								if(location.href.indexOf("#") == -1){
									location.href += "#"+this.pl_id;
								}
							}
						})
					});
				}
			},
			changetypeshow(){
				this.typeshow = !this.typeshow;
				var width = document.body.clientWidth;
				if(this.typeshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0,parent-100)
				});
			},
			changeyearshow(){
				this.yearshow = !this.yearshow;
				var width = document.body.clientWidth;
				if(this.yearshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0,parent-100)
				});
			},
			searchM:function()
			{
				this.searchkey = '';
			},
			setbannershow(abs,isshow){
				this.bannershow[abs] = isshow;
			},
			unlock(){
				document.body.style.overflow = 'unset'
			}
		}

	}
</script>


<style scoped>
	.lf_group_tit_left{
			margin-left: 0.2rem;
		}
		.banner {
			width: 17.88rem;
			height: 1.85rem;
			margin: 0 auto;
			overflow: hidden;
		}
		
		.banner img {
			width: 100%;
			height: 1.85rem;
		}
		
		.table {
			width: 100%;
			overflow: hidden;
			background-color: #FAFAFA;
			margin-top: 0.2rem;
		
		}
		
		.tableitem {
			width: 17.88rem;
			margin: 0 auto;
			height: 1.1rem;
			line-height: 1.1rem;
			color: #666666;
			font-size: 0.24rem;
			text-align: center;
			border-bottom: 0.01rem solid #DBDBDB;
			overflow: hidden;
		}
		
		.tableitemBody {
			width: 17.88rem;
			height: 1.1rem;
			margin: 0 auto;
			line-height: 1.1rem;
			color: #666666;
			font-size: 0.28rem;
			text-align: center;
			border-bottom: 0.01rem solid #DBDBDB;
			overflow: hidden;
		}
		
		
		
		.headwordq {
			width: 5.9rem;
			height: 1.1rem;
			float: left;
		}
		
		
		.headwordp {
			width: 3rem;
			height: 1.1rem;
			float: left;
		}
		
		.headwords {
			width: 4.3rem;
			height: 1.1rem;
			float: left;
		
		}
		
		.bodywordp {
			width: 2.8rem;
			height: 1.1rem;
			font-size: 0.28rem;
			color: #000;
			float: left;
		}
		
		.bodywordq {
			width: 6.2rem;
			height: 1.1rem;
			float: left;
			font-size: 0.28rem;
			color: #000;
		}
		.bodywordq a{
			display: flex;
			align-items: center;
			height: 1.1rem;
		}
		.backWhite{
			background-color: #fff !important;
		}
		
		.center {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			line-height: normal!important;
		}
		
		.bodyname {
			color: #000;
			margin-left: 0.2rem;
			display: flex;
			align-items: center;
			line-height: normal!important;
			text-align: left;
		}
		
		
		.bodynamee {
			font-size: 0.24rem;
			margin-left: 0.2rem;
			display: flex;
			align-items: center;
			word-break: break-all;
			line-height: normal!important;
			text-align: left;
			
		}
		
		.country {
			width: 0.4rem;
			float: left;
		}
		
		.headlogo {
			width: 1.5rem;
			height: 0.5rem;
			
		}
		
		.headlogo img {
			width: 1.5rem;
			height: 0.5rem;
			
		}
		.changeTimeAll{
			position: relative;
		}
		
		
		
		.changetime_new {
			
			width: 2.6rem;
			text-align: center;
			background-color: #C0153E;
			border-radius: 0.1rem;
			height: 0.5rem;
			line-height: 0.5rem;
		
		
			font-size: 0.28rem;
			color: #fff;
			font-weight: bold;
			float: right;
		}
		
		.changeday {
			text-align: right;
			border-radius: 0.1rem;
			height: 0.5rem;
			line-height: 0.5rem;
		
		
			font-size: 0.22rem;
			color: #666666;
			float: right;
		}
		
		.changetime_new img {
			width: 0.3rem;
			height: 0.3rem;
			float: right;
			margin-top: 0.1rem;
			margin-right: 0.5rem;
		}
		
		.timeword_new {
			width: 1.2rem;
			float: left;
			margin-left: 0.5rem;
			height: 0.5rem;
		}
		
		
		.headwordbox {
			width: 18.5rem;
			height: 0.4rem;
			line-height: 0.4rem;
			color: #C0153E;
		
			text-indent: 0.4rem;
			font-size: 0.3rem;
			background-color: #FAFAFA;
			margin-top: 0.5rem;
			margin-left: 0.3rem;
		}
		
		.changetime{
			display: none;
			font-weight: bold;
		}
		.changetimeimg{
			float: right;
			transform: rotate(90deg);
		}
		.country {
			font-size: 0.3rem;
			color: #C0153E;
			font-weight: bold;
		}
		.banner_top{
			width: 100%;
			height: 2.25rem;
		}
		@media screen and (max-width: 768px) {
			.changetime_new{
				display: none;
			}
			.changetime_s{
				display: flex;
				align-items: center;
			}
			.changetime_s .timeword {
				line-height: normal;
			}
			.tableitem {
				width: 7.02rem;
				margin: 0 auto
			}
		
			.tableitemBody {
				width: 7.02rem;
				margin: 0 auto;
				height: 1.2rem;
				line-height: 1.2rem;
		
			}
			.changeday{
				width: 100%;
				
			}
		
			.country {
		
				
			}
		
			.headwordp {
				width: 0.9rem;
				height: 100%;
				float: left;
			}
		
			.headwordq {
				width: 3.5rem;
				height: 100%;
				float: left;
			}
		
			.headwords {
				width: 1.2rem;
				height: 100%;
				float: left;
			}
			.bodywordq a{
				display: flex;
				align-items: center;
				height: 100%;
			}
		
		
			.bodywordp {
				width: 0.9rem;
				height: 100%;
				font-size: 0.28rem;
				color: #000;
				float: left;
			}
		
			.bodywordq {
				width: 3.5rem;
				height: 100%;
				float: left;
				font-size: 0.28rem;
				color: #000;
			}
		
			.center {
				overflow: hidden;
				line-height: normal;
				display: flex;
				flex-wrap:unset;
				flex-direction: column;
				align-items:flex-start;
				height:unset;
			}
		
			.bodyname {
			
				color: #000;
				
				height: fit-content!important;
		text-align: left;
			}
		
		
			.bodynamee {
				font-size: 0.24rem;
				line-height: normal;
				word-break: unset;
				height: fit-content!important;
				
			}
			.lf_LAOLISHI_CENTER{
				overflow: hidden;
				width: 7.5rem;
				height: 0.75rem;
				margin:0.2rem auto;
			}
			.lf_CLPGA_CENTER{
				overflow: hidden;
				width: 7.5rem;
				height: 0.75rem;
				margin:0.2rem auto;
			}
			.headlogo{
				display: none;
			}
			.banner_top{
				width: 100%;
				height: 0.88rem;
			}
		}
</style>